export default {
  uid: '',
  users: [],
  currentUser: {},
  deviceID: '',
  firstUse: false,
  claims: {},
  newsOpen: true,
  viewedUser: {},
  userNotifications: null,
  homePos: null,
  dark: false,
  dirPos: 0,
  // homeScreen: {
  //   directory: 0,
  //   chat: 0,
  //   hr: 0,
  //   offers: 0,
  //   events: 0,
  //   she: 0,
  //   menus: 0,
  //   suggestions: 0,
  //   library: 0,
  //   trivia: 0
  // },
  userTraining: [],
  defSearch: '',
  teamTraining: {}
};
