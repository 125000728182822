import _ from 'lodash';
export const getUnreadCount = (state) => (userId) => {
  if (!state.chats.filter((chat) => chat.participants[userId].active)) return 0;
  return _.sum(
    state.chats
      .filter((chat) => chat.participants[userId].active)
      .filter((x) => x.unread)
      .map((x) => x.unread[userId])
  );
};

export const activeChats = (state) => (userId) => {
  return state.chats.filter((chat) => chat.participants[userId].active);
};

export const archiveChats = (state) => (userId) => {
  return state.chats.filter((chat) => !chat.participants[userId].active);
};
