import _ from 'lodash';

export function startChatListener({ commit }) {
  this.$fs
    .collection('chats')
    .where(`participants.${this.$auth.currentUser.uid}.active`, '!=', null)
    .onSnapshot(
      (chats) => {
        const list = [];
        chats.forEach(async (chat) => {
          await list.push({ id: chat.id, ...chat.data() });
        });
        // TODO Why is updatedAt Null here?
        const sortedList = _.orderBy(
          list,
          (item) => {
            return item.updatedAt.toDate().getTime();
          },
          ['desc']
        );
        commit('updateChatState', { state: 'chats', data: sortedList });
      },
      (err) => {
        console.error(err);
        this.$logger('error', ['[action] startChatListener', err]);
      }
    );
}

export function createChat({ dispatch }, payload) {
  const isSilent = payload.silent ? true : false;

  if (payload.silent) delete payload.silent;
  return this.$fs
    .collection('chats')
    .add({
      ...payload,
      updatedAt: new Date(),
      updatedBy: this.$auth.currentUser.uid
    })
    .then((docRef) => {
      this.$logger('info', [`[action] createChat`, payload]);
      if (!isSilent)
        dispatch('core/popToast', 'positive', {
          root: true
        });
      return docRef.id;
    })
    .catch((err) => {
      this.$logger('error', ['[action] createChat', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function updateChat({ dispatch }, payload) {
  return this.$fs
    .collection('chats')
    .doc(payload.id)
    .update({
      ...payload,
      updatedAt: new Date(),
      updatedBy: this.$auth.currentUser.uid
    })
    .then(() => {
      this.$logger('info', [`[action] updateChat`, payload]);
    })
    .catch((err) => {
      this.$logger('error', ['[action] updateChat', err]);
    });
}

// TODO fix for reintroducing user to chat
export async function newMessage({ state }, payload) {
  const chat = state.chats.find((x) => x.id === payload.chatId);
  const usersToUpdate = Object.keys(payload.participants).filter(
    (x) => x !== payload.message.createdBy
  );
  const unreadObject = { ...chat.unread };
  usersToUpdate.forEach((user) => {
    unreadObject[user] = ++unreadObject[user] || 1;
  });
  return await this.$fs
    .collection('chats')
    .doc(payload.chatId)
    .update({
      updatedAt: this.$fs2.FieldValue.serverTimestamp(),
      messages: this.$fs2.FieldValue.arrayUnion(payload.message),
      unread: unreadObject
    })
    .catch((err) => {
      console.error(err);
    });
}

export function deleteMessage(context, payload) {
  return this.$fs
    .collection('chats')
    .doc(payload.chatId)
    .update({
      messages: this.$fs2.FieldValue.arrayRemove(payload.message)
    })
    .then(() => {
      if (payload.message.media.length) {
        //delete media
        this.$stor.refFromURL(payload.message.media[0]).delete();
      }
      const newMessage = { ...payload.message };
      newMessage.deleted = true;
      this.$fs
        .collection('chats')
        .doc(payload.chatId)
        .update({
          messages: this.$fs2.FieldValue.arrayUnion(newMessage)
        });
    })
    .catch((err) => {
      console.error(err);
    });
}

export function forwardMessage(context, payload) {}

export function leaveChat({ dispatch }, payload) {
  return this.$fs
    .collection('chats')
    .doc(payload.chatId)
    .update({
      [`participants.${payload.userId}.active`]: false,
      left: new Date()
    })
    .then(() => {
      this.$logger('info', [`[action] leaveChat`, payload]);
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] leaveChat', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function rejoinChat({ dispatch }, payload) {
  return this.$fs
    .collection('chats')
    .doc(payload.chatId)
    .update({
      [`participants.${payload.userId}.active`]: true
    })
    .then(() => {
      this.$logger('info', [`[action] rejoinChat`, payload]);
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] rejoinChat', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function clearUnread(context, payload) {
  return this.$fs
    .collection('chats')
    .doc(payload.chat)
    .update({ unread: payload.unread })
    .then(() => {
      this.$logger('info', [`[action] clearUnread`, payload]);
    })
    .catch((err) => {
      this.$logger('error', ['[action] rejoinChat', err]);
    });
}

//OLD
export const copyMessage = ({ commit }, payload) => {
  commit('copyMessage', payload);
};

export const msgReplied = ({ commit }, payload) => {
  commit('msgReplied', payload);
};
