import Vue from 'vue';
import VueRouter from 'vue-router';

import firebase from 'firebase/app';
import 'firebase/auth';

import _ from 'lodash';

import routes from './routes';

Vue.use(VueRouter);

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation
 */

// eslint-disable-next-line space-before-function-paren
export default function ({ store }) {
  const Router = new VueRouter({
    scrollBehavior(to, from, savedPosition) {
      if (to.hash) {
        return { selector: to.hash };
        // } else if (to.path.includes('/messages')) {
        //   return { x: 0, y: 999999999999 };
      } else if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    },
    routes,

    // Leave these as is and change from quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    mode: process.env.VUE_ROUTER_MODE,
    base: process.env.VUE_ROUTER_BASE
  });

  // Configure navigation guard
  Router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
    const requiresAdmin = to.matched.some((x) => {
      return x.meta.requiresAdmin;
    });
    const requiresUserPerm = to.matched.some((x) => x.meta.userPerm);
    const requiresAdminPerm = to.matched.some((x) => x.meta.adminPerm);
    const requiresFeature = to.matched.some((x) => x.meta.feature);

    const userPerm = to.meta.userPerm; // TODO or it it's a SUPERUSER...
    const adminPerm = to.meta.adminPerm; // TODO or it it's a SUPERUSER...
    const feature = to.meta.feature;

    const initializeAuth = new Promise((resolve) => {
      // this adds a hook for the initial auth-change event
      firebase.auth().onAuthStateChanged((user) => {
        // console.log('refreshing auth state')
        resolve(user);
      });
    });

    initializeAuth.then(async (user) => {
      if (user && !store.state.core.initComplete) {
        await store.dispatch('core/initApp');
      }
      if (user) {
        // TODO Conditionalise this to only run once per day.
        store.dispatch('user/updateDAU', user.uid);
      }

      user &&
        (await user
          .getIdToken()
          .then(() => {
            return user.getIdTokenResult(true);
          })
          .then(async (idTokenResult) => {
            const expires = new Date(idTokenResult.expirationTime);
            const now = new Date();
            if (now > expires) {
              await user.getIdTokenResult(true);
            }

            store.commit('user/updateUserState', {
              state: 'roles',
              data: idTokenResult.claims.roles
            });
          })
          .catch((err) => {
            console.error('init auth', err);
          }));

      const features = {
        ...store.state.admin.features
      };

      const userClaims = {
        admin: [],
        user: []
      };

      const deny = {
        admin: [],
        user: []
      };

      let isSuperUser = true;
      if (user) {
        const userRoles = []; //[...store.state.user.roles];
        const roles = []; //[...store.state.admin.roles];
        // isSuperUser = store.state.user.roles.includes('SUPERUSER')
        //   ? true
        //   : false;

        userRoles.forEach((role) => {
          if (!roles.find((x) => x.id === role)) return;

          const roleAdminClaims = roles.find((x) => x.id === role).claims.admin;
          const roleUserClaims = roles.find((x) => x.id === role).claims.user;
          Object.entries(roleAdminClaims).forEach(([key, value]) => {
            if (value === 'allow') userClaims.admin.push(key);
            //Handle deny
            if (value === 'deny') deny.admin.push(key);
          });
          Object.entries(roleUserClaims).forEach(([key, value]) => {
            if (value === 'allow') userClaims.user.push(key);
            //Handle deny
            if (value === 'deny') deny.user.push(key);
          });
        });
        userClaims.admin = _.difference(userClaims.admin, deny.admin);
        userClaims.user = _.difference(userClaims.user, deny.user);
        store.commit('user/updateUserState', {
          state: 'claims',
          data: userClaims
        });
      }

      const hasAdmin = !!userClaims.admin.length || isSuperUser;

      var currentUser = user && !user.isAnonymous;
      if (requiresAuth && !currentUser) next('/login');
      if (requiresAdmin && currentUser && !hasAdmin) {
        next(from.path);
      }
      if (
        requiresAuth &&
        currentUser &&
        requiresFeature &&
        !features[feature]
      ) {
        next('/404');
      }
      if (
        requiresAuth &&
        currentUser &&
        requiresUserPerm &&
        !userClaims.user.includes(userPerm) &&
        !isSuperUser
      ) {
        next('/no-permission');
      }
      if (
        requiresAuth &&
        currentUser &&
        requiresAdminPerm &&
        !userClaims.admin.includes(adminPerm) &&
        !isSuperUser
      ) {
        next('/no-permission');
      }

      if (
        requiresAuth &&
        currentUser &&
        requiresUserPerm &&
        userClaims.user.includes(userPerm)
      )
        next();
      else if (requiresAuth && currentUser) next();
      else next();
    });
  });

  return Router;
}
