export function getLearning({ commit }) {
  return this.$fs
    .collection('learning')
    .get()
    .then((fsData) => {
      const list = [];
      fsData.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
      this.$logger('info', [`[action] getLearning`, list]);
      commit('updateLearningState', {
        state: 'learning',
        data: list
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getLearning', err]);
    });
}

export function getUserLearning({ commit }, payload) {
  return this.$fs
    .collection('users')
    .doc(payload)
    .collection('learning')
    .get()
    .then((fsData) => {
      const list = [];
      fsData.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
      this.$logger('info', [`[action] getUserLearning`, list]);
      commit('updateNestedLearningState', {
        state: 'userLearning',
        nestedPath: payload,
        data: list
      });
    })
    .catch((err) => {
      console.error(err);
      this.$logger('error', ['[action] getUserLearning', err]);
    });
}

export function createLearning({ dispatch }, payload) {
  return this.$fs
    .collection('learning')
    .add(payload)
    .then(async () => {
      this.$logger('info', [`[action] createLearning`, payload]);
      await dispatch('getLearning');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] createLearning', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function updateLearning({ dispatch }, payload) {
  return this.$fs
    .collection('learning')
    .doc(payload.id)
    .update(payload)
    .then(async () => {
      this.$logger('info', [`[action] updateLearning`, payload]);
      await dispatch('getLearning');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] updateLearning', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function deleteLearning({ dispatch }, payload) {
  return (
    this.$fs
      .collection('learning')
      .doc(payload)
      .delete()
      // TODO remove all learning course uploads (image, videos etc.)
      // TODO remove all learning records from course.
      // .then(async () => {
      //   const urlsToDelete = payload.menuFiles
      //     .filter((x) => x.downloadUrl.length)
      //     .map((x) => x.downloadUrl);
      //   for (const url of urlsToDelete) {
      //     await this.$stor.refFromURL(url).delete();
      //   }
      //   return;
      // })
      .then(async () => {
        this.$logger('info', [`[action] deleteLearning`, payload]);
        await dispatch('getLearning');
        dispatch('core/popToast', 'positive', {
          root: true
        });
      })
      .catch((err) => {
        this.$logger('error', ['[action] deleteLearning', err]);
        dispatch('core/popToast', 'negative', {
          root: true
        });
      })
  );
}

export function resetLearningAttempts({ dispatch }, payload) {
  this.$fs
    .collection('users')
    .doc(payload.userId)
    .collection('learning')
    .doc(payload.learningId)
    .set(
      {
        attempts: [],
        passed: null,
        quizFinished: null,
        quizStarted: null,
        started: null,
        currentStep: 0,
        curentQuizStep: 0,
        pastAttempts: payload.pastAttempts,
        resetRequested:false
      },
      { merge: true }
    )
    .then(async () => {
      this.$logger('info', [`[action] resetLearningAttempts`, payload]);
      await dispatch('getLearning');
      await dispatch('getUserLearning', payload.userId);
      dispatch('core/popToast', 'positive', {
        root: true
      });
      // TODO refactor fb function to support this model change
      this.$func.httpsCallable('learning-notifyUserAfterRequestForAttempts')({
        user: payload.userId,
        module: payload.learningId
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] resetLearningAttempts', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function createLearningCategory({ dispatch }, payload) {
  return this.$fs
    .collection('learningCategories')
    .add(payload)
    .then(async () => {
      this.$logger('info', [`[action] createLearningCategory`, payload]);
      await dispatch('getLearningCategories');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] createLearningCategory', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function getLearningCategories({ commit }) {
  return this.$fs
    .collection('learningCategories')
    .get()
    .then((fsData) => {
      const list = [];
      fsData.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
      this.$logger('info', [`[action] getLearningCategories`, list]);
      commit('updateLearningState', {
        state: 'categories',
        data: list
      });
    })
    .catch((err) => {
      console.error(err);
      this.$logger('error', ['[action] getLearningCategories', err]);
    });
}

export function deleteLearningCategory({ dispatch }, payload) {
  return this.$fs
    .collection('learningCategories')
    .doc(payload.categoryId)
    .delete()
    .then(async () => {
      const updateBatch = this.$fs.batch();

      for (const doc of payload.docsToUpdate) {
        updateBatch.update(this.$fs.collection('learning').doc(doc.id), {
          category: null
        });
      }
      return await updateBatch.commit();
    })
    .then(async () => {
      this.$logger('info', [`[action] deleteLearningCategory`, payload]);
      await dispatch('getLearningCategories');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] deleteLearningCategory', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function updateLearningCategory({ dispatch }, payload) {
  return this.$fs
    .collection('learningCategories')
    .doc(payload.categoryId)
    .update({
      updatedBy: this.$auth.currentUser.uid,
      updatedAt: this.$fs2.FieldValue.serverTimestamp(),
      title: payload.title
    })
    .then(async () => {
      this.$logger('info', [`[action] updateLearningCategory`, payload]);
      await dispatch('getLearningCategories');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] updateLearningCategory', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function issueCourse({ dispatch }, payload) {
  return this.$fs
    .collection('learning')
    .doc(payload.id)
    .update({
      issuances: this.$fs2.FieldValue.arrayUnion(payload.issuance),
      invitees: this.$fs2.FieldValue.arrayUnion(...payload.invitees)
    })
    .then(async () => {
      this.$logger('info', [`[action] issueCourse`, payload]);
      await dispatch('getLearning');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] issueCourse', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export async function getTeamLearning({ commit }, team) {
  const resultObj = {};
  for (const user of team) {
    await this.$fs
      .collection('users')
      .doc(user.uid)
      .collection('learning')
      .get()
      .then((fsData) => {
        const list = [];
        fsData.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
        // this.$logger('info', [`[action] getTeamLearning`, list]);
        return (resultObj[user.uid] = list);
      })
      .catch((err) => {
        console.error(err);
        this.$logger('error', ['[action] getTeamLearning', err]);
      });
  }

  commit('updateLearningState', {
    state: 'teamLearning',
    data: resultObj
  });
}
