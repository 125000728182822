export function getMenus({ commit }) {
  return this.$fs
    .collection('menus')
    .get()
    .then((fsData) => {
      const list = [];
      fsData.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
      this.$logger('info', [`[action] getMenus`, list]);
      commit('updateMenusState', {
        state: 'menus',
        data: list
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getMenus', err]);
    });
}

export function createMenu({ dispatch }, payload) {
  return this.$fs
    .collection('menus')
    .add(payload)
    .then(async () => {
      this.$logger('info', [`[action] createMenu`, payload]);
      await dispatch('getMenus');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] createMenu', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function updateMenu({ dispatch }, payload) {
  return this.$fs
    .collection('menus')
    .doc(payload.id)
    .update(payload)
    .then(async () => {
      this.$logger('info', [`[action] updateMenu`, payload]);
      await dispatch('getMenus');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] updateMenu', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function deleteMenu({ dispatch }, payload) {
  return this.$fs
    .collection('menus')
    .doc(payload.id)
    .delete()
    .then(async () => {
      const files = payload.menuFiles;
      const allFiles = [];
      for (const locale in files) {
        allFiles.push(files[locale].downloadUrl);
      }
      const urlsToDelete = allFiles.filter((x) => x !== undefined);
      for (const url of urlsToDelete) {
        await this.$stor.refFromURL(url).delete();
      }
      return;
    })
    .then(async () => {
      this.$logger('info', [`[action] deleteMenu`, payload]);
      await dispatch('getMenus');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] deleteMenu', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}
