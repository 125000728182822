export function getLibrary({ commit }) {
  return this.$fs
    .collection('library')
    .orderBy('title')
    .get()
    .then((fsData) => {
      const list = [];
      fsData.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
      this.$logger('info', [`[action] getLibrary`, list]);
      commit('updateLibraryState', {
        state: 'library',
        data: list
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getLibrary', err]);
    });
}



export function getFolderStructure({ commit }) {
  return this.$fs
    .collection('folderStructure')
    .get()
    .then((fsData) => {
      const list = [];
      //const flat=[];
      fsData.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
      // fsData.docs.forEach((doc) => {
      //   flat.push(doc.data());
      // });
      this.$logger('info', [`[action] getFolderStructure`, list]);
      commit('updateLibraryState', {
        state: 'structure',
        data: list
      });
      // commit('updateLibraryState', {
      //   state: 'flatStructure',
      //   data: flat
      // });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getFolderStructure', err]);
    });
  }





export function createDocument({ dispatch }, payload) {
  return this.$fs
    .collection('library')
    .add(payload)
    .then(async () => {
      this.$logger('info', [`[action] createDocument`, payload]);
      await dispatch('getLibrary');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] createDocument', err]);
    });
}

export function updateDocument({ dispatch }, payload) {
  return this.$fs
    .collection('library')
    .doc(payload.id)
    .update(payload)
    .then(async () => {
      this.$logger('info', [`[action] updateDocument`, payload]);
      await dispatch('getLibrary');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] updateDocument', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function deleteDocuments({ dispatch }, payload) {
  const deleteActions = [];

  const deleteAction = ({ id, downloadUrl }) =>
    this.$fs
      .collection('library')
      .doc(id)
      .delete()
      .then(async () => {
        //delete document file
        if (!!downloadUrl)
          return await this.$stor.refFromURL(downloadUrl).delete();
      });

  for (const document of payload) {
    deleteActions.push(deleteAction(document));
  }

  return Promise.all(deleteActions)
    .then(async () => {
      await dispatch('getLibrary');
      dispatch('core/popToast', 'positive', {
        root: true
      });
      this.$logger('info', [`[action] deleteDocuments`, payload]);
    })
    .catch((err) => {
      this.$logger('error', ['[action] deleteDocuments', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function getSingleDocument({ commit }, payload) {
  return this.$fs
    .collection('library')
    .doc(payload)
    .get()
    .then((fsData) => {
      this.$logger('info', [`[action] getSingleDocument`, payload]);
      commit('updateLibraryState', {
        state: 'document',
        data: { id: fsData.id, ...fsData.data() }
      });
    })
    .catch((err) => {
      console.error(err);
      this.$logger('error', ['[action] getSingleDocument', err]);
    });
}

export function recordDocumentView({ dispatch }, { documentId, userId }) {
  this.$fs
    .collection('library')
    .doc(documentId)
    .update({
      views: this.$fs2.FieldValue.arrayUnion({
        user: userId,
        createdAt: new Date()
      })
    })
    .then(() => {
      this.$logger('info', [`[action] recordDocumentView`, documentId]);
    })
    .catch((err) => {
      this.$logger('error', ['[action] recordDocumentView', err]);
    });
}

export function getStructure({ commit }) {
  return this.$fs
    .collection('folderStructure')
    .get()
    .then((result) => {
      const flatStructure = [];
      result.docs.forEach((doc) => {
        flatStructure.push(doc.data());
      });
      commit('updateLibraryState', {
        state: 'flatStructure',
        data: _.cloneDeep(flatStructure)
      });
      return flatStructure;
    })
    .then((flatStructure) => {
      const structure = [];
      if (!flatStructure.length) {
        structure.push({ id: 'root', name: '', children: [] });
      }
      flatStructure.forEach((node) => {
        if (!node.parent) return (structure[0] = node);
        const parentIndex = flatStructure.findIndex(
          (el) => el.id === node.parent
        );
        if (!node.children) node.children = [];
        if (!flatStructure[parentIndex].children) {
          return (flatStructure[parentIndex].children = [node]);
        }
        flatStructure[parentIndex].children.push(node);
      });
      return structure;
    })
    .then((structure) => {
      this.$logger('info', [`[action] getStructure`, structure]);
      commit('updateLibraryState', {
        state: 'structure',
        data: structure
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getStructure', err]);
    });
}
