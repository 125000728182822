export function updateLogin(state, payload) {
  state.login = payload;
}
export function toggleReply(state, payload) {
  state.reply = payload;
}
export function toggleEvent(state, payload) {
  state.event = payload;
}
export function toggleGroup(state, payload) {
  state.group = payload;
}
export function toggleUser(state, payload) {
  state.user = payload;
}
export function toggleNews(state, payload) {
  state.news = payload;
}
export function toggleBlog(state, payload) {
  state.blog = payload;
}
export function toggleLink(state, payload) {
  state.link = payload;
}
export function togglePush(state, payload) {
  state.push = payload;
}
export function togglePostImage(state, payload) {
  state.postImage = payload;
}
export function toggleFirstUse(state, payload) {
  state.firstUse = payload;
}
export function toggleImage(state, payload) { 
  state.toggleDefaultArticleImage = payload;
}
export function toggleUserStatuses(state, payload) { 
  state.userStatuses = payload;
}

export function toggelChannels(state, payload) { 
  state.channelsAdmin = payload;
}