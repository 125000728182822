export const getUsers = (state) => {
  return state.users;
};
export const getCurrentUser = (state) => {
  return state.currentUser;
};
export const getUserNames = (state) => {
  return state.users.map((x) => {
    return { id: x.uid, name: `${x.first} ${x.last}`, email: x.email };
  });
};
export const getSearchSetting = (state) => {
  return state.currentUser.preferences;
};

export const isSuperUser = (state) => {
  return state.roles.includes('SUPERUSER');
};
