import { render, staticRenderFns } from "./AppLink.vue?vue&type=template&id=503b8193&"
import script from "./AppLink.vue?vue&type=script&lang=js&"
export * from "./AppLink.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QBanner,QBtn});qInstall(component, 'directives', {ClosePopup});
