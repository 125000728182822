export const userCompetitions = (state) => (id) => {
  const userCompetitions = state.competitions.filter(
    (x) => x.invitees.includes(id) || x.createdBy === id
  );

  return userCompetitions.filter((competition) => {
    const now = new Date();
    //event.timeEnd.substring(0,2));//hours
    //event.timeEnd.substring(3,5));//minutes
    if (!competition.dateEnd || !competition.dateStart) return false;
    return competition.dateEnd
      ? new Date(
          competition.dateEnd
            .toDate()
            .setHours(
              +competition.timeEnd.substring(0, 2),
              +competition.timeEnd.substring(3, 5)
            )
        ) >= now
      : new Date(
          competition.dateStart
            .toDate()
            .setHours(
              +competition.timeStart.substring(0, 2),
              +competition.timeStart.substring(3, 5)
            )
        ) >= now;
  });
};

export const competitionComments = (state) => (id) => {
  return state.competitionComments
    .filter((x) => x.competitionId === id)
    .filter((x) => !x.deleted)
    .filter((x) => !x.removedByModerator);
};
