export function startUserNotificationsListener({ commit }) {
  this.$fs
    .collection('userNotifications')
    .doc(this.$auth.currentUser.uid)
    .onSnapshot(
      (doc) => {
        if (doc.exists) {
          commit('updateNotificationsState', {
            state: 'notifications',
            data: doc.data().updates
          });
        }
      },
      (err) => {
        console.error('startUserNotificationsListener', err);
      }
    );
}

export function dismissUpdate({ dispatch }, payload) {
  // return this.$fs
  //   .collection('userNotifications')
  //   .doc(this.$auth.currentUser.uid)
  //   .update({
  //     updates: payload
  //   })
  //   .then(() => {
  //     console.log('dismissed');
  //   });
}

export function snoozeUpdate({ dispatch }, payload) {}

export function createNotification({ commit, dispatch }, payload) {
  return this.$func.httpsCallable('sendNotification')(payload);
}
