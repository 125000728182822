export function getSurveys({ commit }) {
  return this.$fs
    .collection('surveys')
    .orderBy('createdAt', 'desc')
    .get()
    .then((fsData) => {
      const list = [];
      fsData.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
      this.$logger('info', [`[action] getSurveys`, list]);
      commit('updateSurveyState', {
        state: 'surveys',
        data: list
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getSurveys', err]);
    });
}

export function createSurvey({ dispatch }, payload) {
  return this.$fs
    .collection('surveys')
    .add(payload)
    .then(async () => {
      this.$logger('info', [`[action] createSurvey`, payload]);
      await dispatch('getSurveys');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] createSurvey', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function updateSurvey({ dispatch }, payload) {
  return this.$fs
    .collection('surveys')
    .doc(payload.id)
    .update(payload)
    .then(async () => {
      this.$logger('info', [`[action] updateSurvey`, payload]);
      await dispatch('getSurveys');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] updateSurvey', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function submitSurveyResponse({ dispatch }, payload) {
  const submitResponse = this.$fs
    .collection('surveyResults')
    .doc(payload.id)
    .set({ [payload.user]: payload.data }, { merge: true })
    .then(() => {
      this.$logger('info', [
        `[action] submitSurveyResponse-submitResponse`,
        payload
      ]);
    })
    .catch((err) => {
      this.$logger('error', [
        '[action] submitSurveyResponse-submitResponse',
        err
      ]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });

  const updateParticipants = this.$fs
    .collection('surveys')
    .doc(payload.id)
    .update({ participants: this.$fs2.FieldValue.arrayUnion(payload.user) })
    .then(async () => {
      this.$logger('info', [
        `[action] submitSurveyResponse-updateParticipants`,
        payload
      ]);
      await dispatch('getSurveys');
    })
    .catch((err) => {
      this.$logger('error', [
        '[action] submitSurveyResponse-updateParticipants',
        err
      ]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });

  return Promise.all([submitResponse, updateParticipants]);
}

export function getSingleSurveyResults({ commit }, payload) {
  return this.$fs
    .collection('surveyResults')
    .doc(payload)
    .get()
    .then((fsData) => {
      this.$logger('info', [`[action] getSingleSurveyResults`, fsData.data()]);
      // return fsData.data();
      commit('updateSurveyState', {
        state: 'currentSurveyResults',
        data: fsData.exists ? fsData.data() : {}
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getSingleSurveyResults', err]);
    });
}

export function deleteSurveys({ dispatch }, payload) {
  const deleteActions = [];

  const deleteAction = (id) =>
    this.$fs
      .collection('surveys')
      .doc(id)
      .delete()
      .then(async () => {
        //delete results
        await this.$fs
          .collection('surveyResults')
          .doc(id)
          .delete();
      });

  for (const survey of payload) {
    deleteActions.push(deleteAction(survey));
  }

  return Promise.all(deleteActions)
    .then(async () => {
      await dispatch('getSurveys');
      dispatch('core/popToast', 'positive', {
        root: true
      });
      this.$logger('info', [`[action] deleteSurveys`, payload]);
    })
    .catch((err) => {
      this.$logger('error', ['[action] deleteSurveys', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}
