export const updateLearningState = (state, payload) => {
  state[payload.state] = payload.data;
};
export const updateNestedLearningState = (state, payload) => {
  state[payload.state][payload.nestedPath] = payload.data;
};

// export const createLearning = (state, payload) => {
//   state.learning = payload;
// };

export const getLearning = (state, payload) => {
  state.learning = payload;
};

export const getAllLearningResults = (state, payload) => {
  state.results = payload;
};

// export const changeEndDate = (state, payload) => {
//   state.learning = payload;
// };
// export const changeDueDate = (state, payload) => {
//   state.learning = payload;
// };

export const updateCurrentCourse = (state, payload) => {
  state.currentCourse = payload;
};

// export const updateUserCourseInfo = (state, payload) => {
//   state.currentCourseUserInfo = payload;
// };

// export const updateLearningCategories = (state, payload) => {
//   state.categories = payload;
// };
