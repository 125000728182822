export function getPolls({ commit }) {
  return this.$fs
    .collection('polls')
    .orderBy('createdAt', 'desc')
    .get()
    .then((fsData) => {
      const list = [];
      fsData.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
      this.$logger('info', [`[action] getPolls`, list]);
      commit('updatePollState', {
        state: 'polls',
        data: list
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getPolls', err]);
    });
}

export function createPoll({ dispatch }, payload) {
  return this.$fs
    .collection('polls')
    .add(payload)
    .then(async () => {
      this.$logger('info', [`[action] createPoll`, payload]);
      await dispatch('getPolls');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] createPoll', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function updatePoll({ dispatch }, payload) {
  return this.$fs
    .collection('polls')
    .doc(payload.id)
    .update(payload)
    .then(async () => {
      this.$logger('info', [`[action] updatePoll`, payload]);
      await dispatch('getPolls');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] updatePoll', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function castVote({ dispatch }, payload) {
  const addVote = this.$fs
    .collection('pollResults')
    .doc(payload.id)
    .set({ [payload.user]: payload.data }, { merge: true })
    .then(() => {
      this.$logger('info', [`[action] castVote-addVote`, payload]);
    })
    .catch((err) => {
      this.$logger('error', ['[action] castVote-addVote', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });

  const updateParticipants = this.$fs
    .collection('polls')
    .doc(payload.id)
    .update({ participants: this.$fs2.FieldValue.arrayUnion(payload.user) })
    .then(async () => {
      this.$logger('info', [`[action] castVote-updateParticipants`, payload]);
      await dispatch('getPolls');
      await dispatch('getSinglePollResults', payload.id);
    })
    .catch((err) => {
      this.$logger('error', ['[action] castVote-updateParticipants', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });

  return Promise.all([addVote, updateParticipants]);
}

export function getSinglePollResults({ commit }, payload) {
  return this.$fs
    .collection('pollResults')
    .doc(payload)
    .get()
    .then((fsData) => {
      this.$logger('info', [`[action] getSinglePollResults`, fsData.data()]);
      // return fsData.data();
      commit('updatePollState', {
        state: 'currentPollResults',
        data: fsData.exists ? fsData.data() : {}
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getSinglePollResults', err]);
    });
}

export function deletePolls({ dispatch }, payload) {
  const deleteActions = [];

  const deleteAction = (id) =>
    this.$fs
      .collection('polls')
      .doc(id)
      .delete()
      .then(async () => {
        //delete poll results
        await this.$fs
          .collection('pollResults')
          .doc(id)
          .delete();
      });

  for (const poll of payload) {
    deleteActions.push(deleteAction(poll));
  }

  return Promise.all(deleteActions)
    .then(async () => {
      await dispatch('getPolls');
      dispatch('core/popToast', 'positive', {
        root: true
      });
      this.$logger('info', [`[action] deletePolls`, payload]);
    })
    .catch((err) => {
      this.$logger('error', ['[action] deletePolls', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}
