export function getArticles({ commit }) {
  return this.$fs
    .collection('articles')
    .orderBy('createdAt', 'desc')
    .get()
    .then((fsData) => {
      const list = [];
      fsData.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
      this.$logger('info', [`[action] getArticles`, list]);
      commit('updateArticlesState', {
        state: 'articles',
        data: list
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getArticles', err]);
    });
}

export function createArticle({ dispatch }, payload) {
  return this.$fs
    .collection('articles')
    .add(payload)
    .then(async () => {
      this.$logger('info', [`[action] createArticle`, payload]);
      await dispatch('getArticles');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] createArticle', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function updateArticle({ dispatch }, payload) {
  return this.$fs
    .collection('articles')
    .doc(payload.id)
    .update(payload)
    .then(async () => {
      this.$logger('info', [`[action] updateArticle`, payload]);
      await dispatch('getArticles');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] updateArticle', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function deleteArticle({ dispatch }, payload) {
  return this.$fs
    .collection('articles')
    .doc(payload.id)
    .delete()
    .then(async () => {
      if (!payload.type === 'rich') return;

      const urlsToDelete = [];
      //Deal with the header image if present
      if (payload.headerImg) urlsToDelete.push(payload.headerImg);

      const parser = new DOMParser();
      //  Handle all locales with content
      const localesWithContent = Object.entries(payload.content)
        .filter(([key, value]) => !!value)
        .map((x) => x[0]);

      //Iterate over locales and extract all img urls
      for (const locale of localesWithContent) {
        const contentDoc = parser.parseFromString(
          payload.content[locale],
          'text/html'
        );
        const imgs = contentDoc.getElementsByTagName('img');
        //Add urls to ursl to delte
        for (const img of imgs) {
          urlsToDelete.push(img.getAttribute('src'));
        }
      }

      //perform delete operations
      for (const url of urlsToDelete) {
        await this.$stor.refFromURL(url).delete();
      }
      return;
    })
    .then(async () => {
      this.$logger('info', [`[action] deleteArticle`, payload]);
      await dispatch('getArticles');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      console.error(err);
      this.$logger('error', ['[action] deleteArticle', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function getArticlesDefaults({ commit }) {
  return this.$fs
    .collection('setup')
    .doc('articles')
    .get()
    .then((doc) => {
      this.$logger('info', [`[action] getArticlesDefaults`, doc]);
      if (doc.exists) {
        commit('updateArticlesState', {
          state: 'defaults',
          data: doc.data()
        });
      }
    })
    .catch((err) => {
      this.$logger('error', ['[action] getArticles', err]);
    });
}

export function getSingleArticle({ commit }, payload) {
  return this.$fs
    .collection('articles')
    .doc(payload)
    .get()
    .then((doc) => {
      if (!doc.exists) this.$router.push('/404');
      this.$logger('info', [`[action] getSingleArticle`, payload]);
      commit('updateArticlesState', {
        state: 'viewArticle',
        data: doc.data()
      });
      return doc.data();
    })
    .catch((err) => {
      this.$logger('error', ['[action] getSingleArticle', err]);
    });
}

export function getComments({ commit }) {
  return this.$fs
    .collection('comments')
    .orderBy('createdAt', 'desc')
    .get()
    .then((fsData) => {
      const list = [];
      fsData.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
      this.$logger('info', [`[action] getComments`, list]);
      commit('updateArticlesState', {
        state: 'comments',
        data: list
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getComments', err]);
    });
}

export function createArticleComment({ dispatch }, payload) {
  return this.$fs
    .collection('comments')
    .add(payload)
    .then(async () => {
      this.$logger('info', [`[action] createArticleComment`, payload]);
      await dispatch('getComments');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] createArticleComment', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function updateArticleComment({ dispatch }, payload) {
  return this.$fs
    .collection('comments')
    .doc(payload.data.id)
    .update(payload.data)
    .then(async () => {
      this.$logger('info', [`[action] updateArticleComment`, payload]);
      await dispatch('getComments');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] updateArticleComment', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function deleteArticleComment({ dispatch }, payload) {
  return this.$fs
    .collection('comments')
    .doc(payload)
    .update({
      deleted: true,
      deletedAt: this.$fs2.FieldValue.serverTimestamp()
    })
    .then(async () => {
      this.$logger('info', [`[action] deleteArticleComment`, payload]);
      await dispatch('getComments');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] deleteArticleComment', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function reportComment(context, { comment, reportedBy, reportedAt }) {
  return this.$fs
    .collection('comments')
    .doc(comment.id)
    .update({
      requiresModeration: true,
      ['moderation.reports']: this.$fs2.FieldValue.arrayUnion({
        reportedAt,
        reportedBy
      })
    });
}

export function reportContent(context, { content, reportedBy, reportedAt }) {
  return this.$fs
    .collection('userContent')
    .doc(content.id)
    .update({
      requiresModeration: true,
      ['moderation.reports']: this.$fs2.FieldValue.arrayUnion({
        reportedAt,
        reportedBy
      })
    });
}

export function getReportedComments({ commit }) {
  return this.$fs
    .collection('comments')
    .where('requiresModeration', '==', true)
    .orderBy('createdAt', 'desc')
    .get()
    .then((fsData) => {
      const list = [];
      fsData.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
      this.$logger('info', [`[action] getReportedComments`, list]);
      commit('updateArticlesState', {
        state: 'reportedComments',
        data: list
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getReportedComments', err]);
    });
}

export function getReportedContent({ commit }) {
  return this.$fs
    .collection('userContent')
    .where('requiresModeration', '==', true)
    .orderBy('createdAt', 'desc')
    .get()
    .then((fsData) => {
      const list = [];
      fsData.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
      this.$logger('info', [`[action] getReportedContent`, list]);
      commit('updateArticlesState', {
        state: 'reportedContent',
        data: list
      });
      return list;
    })
    .catch((err) => {
      this.$logger('error', ['[action] getReportedContent', err]);
    });
}

export function recordView({ dispatch }, { articleId, user, createdAt }) {
  return this.$fs
    .collection('articles')
    .doc(articleId)
    .update({
      views: this.$fs2.FieldValue.arrayUnion({ user, createdAt })
    })
    .then(() => {
      dispatch('getArticles');
      this.$logger('info', [`[action] recordView`]);
    })
    .catch((err) => {
      this.$logger('error', ['[action] recordView', err]);
    });
}

export function createChannel({ dispatch }, payload) {
  return this.$fs
    .collection('channels')
    .add(payload)
    .then(async () => {
      this.$logger('info', [`[action] createChannel`, payload]);
      await dispatch('admin/getChannels', null, { root: true });
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] createChannel', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function updateChannel({ dispatch }, payload) {
  return this.$fs
    .collection('channels')
    .doc(payload.id)
    .update(payload)
    .then(async () => {
      this.$logger('info', [`[action] updateChannel`, payload]);
      await dispatch('admin/getChannels', null, { root: true });
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] updateChannel', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function deleteChannel({ dispatch }, id) {
  return this.$fs
    .collection('channels')
    .doc(id)
    .delete()
    .then(async () => {
      this.$logger('info', [`[action] deleteChannel`, id]);
      await dispatch('admin/getChannels', null, { root: true });
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] deleteChannel', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function startArticleListener({ commit }) {
  return this.$fs
    .collection('articles')
    .orderBy('createdAt', 'desc')
    .onSnapshot(
      (fsData) => {
        const list = [];
        fsData.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
        this.$logger('info', [`[action] startArticleListener`, list]);
        commit('updateArticlesState', {
          state: 'articles',
          data: list
        });
      },
      (err) => {
        this.$logger('error', ['[action] startArticleListener', err]);
      }
    );
}

export function startCommentListener({ commit }) {
  return this.$fs
    .collection('comments')
    .orderBy('createdAt', 'desc')
    .onSnapshot(
      (fsData) => {
        const list = [];
        fsData.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
        this.$logger('info', [`[action] getComments`, list]);
        commit('updateArticlesState', {
          state: 'comments',
          data: list
        });
      },
      (err) => {
        this.$logger('error', ['[action] startCommentListener', err]);
      }
    );
}

export function createUserContent({ dispatch }, payload) {
  return this.$fs
    .collection('userContent')
    .add(payload)
    .then(async () => {
      this.$logger('info', [`[action] createUserContent`, payload]);
      await dispatch('getUserContent');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] createUserContent', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}
export function deleteUserContent({ dispatch }, payload) {
  return this.$fs
    .collection('userContent')
    .doc(payload)
    .update({
      deleted: true,
      deletedAt: this.$fs2.FieldValue.serverTimestamp()
    })
    .then(async () => {
      this.$logger('info', [`[action] deleteUserContent`, payload]);
      await dispatch('getUserContent');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] deleteUserContent', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function getUserContent({ commit }) {
  return this.$fs
    .collection('userContent')
    .orderBy('createdAt', 'desc')
    .get()
    .then((fsData) => {
      const list = [];
      fsData.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
      this.$logger('info', [`[action] getUserContent`, list]);
      commit('updateArticlesState', {
        state: 'userContent',
        data: list
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getUserContent', err]);
    });
}

export function updateUserContent({ dispatch }, payload) {
  return this.$fs
    .collection('userContent')
    .doc(payload.id)
    .update(payload)
    .then(async () => {
      this.$logger('info', [`[action] updateUserContent`, payload]);
      await dispatch('getUserContent');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] updateUserContent', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}
