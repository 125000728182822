// import something here
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
// import 'firebase/database';
import 'firebase/functions';
import 'firebase/firestore';

const config = process.env.IMPULSE.FB_CONFIG;

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

export const AUTH = firebase.auth();
export const AUTH2 = firebase.auth;
export const STOR = firebase.storage();
// export const DB = firebase.database();
export const FS = firebase.firestore();
export const FS2 = firebase.firestore;
export const FUNC = firebase.app().functions('europe-west1');

if (process.env.DEV && process.env.IMPULSE.EMULATE) {
  const url = new URL(process.env.APP_URL);

  // const url = new URL(process.env.APP_URL);
  FUNC.useEmulator(url.hostname, 5001);
  // FS.useEmulator(url.hostname, 5002);
  // FS.settings({
  //   host: `${url.hostname}:5002`,
  //   ssl: false
  // });
  // AUTH.useEmulator(`http://${url.hostname}:9099`);
}

if (process.env.MODE === 'cordova') {
  if (process.env.IMPULSE.EMULATE) {
    const url = new URL(process.env.APP_URL);
    FUNC.useEmulator(url.hostname, 5001);
    FS.useEmulator(url.hostname, 5002);
    FS.settings({
      cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
      host: `${url.hostname}:5002`,
      ssl: false
    });
  } else {
    FS.settings({
      cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
    });
  }

  FS.enablePersistence()
    .then(() => {
      console.info('persistance enabled');
    })
    .catch((err) => {
      if (err.code == 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
        console.error('too many tabs for persistance');
      } else if (err.code == 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
        console.error('no support for persistance');
      } else {
        console.error(err);
      }
    });
}

export default async ({ Vue, store }) => {
  Vue.prototype.$auth = AUTH;
  Vue.prototype.$auth2 = AUTH2;
  Vue.prototype.$stor = STOR;
  // Vue.prototype.$db = DB;
  Vue.prototype.$fs = FS;
  Vue.prototype.$fs2 = FS2;
  Vue.prototype.$func = FUNC;

  store.$auth = AUTH;
  store.$auth2 = AUTH2;
  store.$stor = STOR;
  // store.$db = DB;
  store.$fs = FS;
  store.$fs2 = FS2;
  store.$func = FUNC;
};
