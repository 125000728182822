// export function reportProblem({ dispatch }, payload) {
//   return this.$fs
//     .collection('tickets')
//     .add(payload)
//     .then((doc) => {
//       dispatch('getCreatedTickets', this.$auth.currentUser.uid);
//       this.$logger('info', [`[action] reportProblem`, doc.data()]);
//     })
//     .catch((err) => {
//       this.$logger('error', ['[action] reportProblem', err]);
//     });
// }

// export function getProblems({ commit }) {
//   return this.$fs
//     .collection('tickets')
//     .get()
//     .then((fsData) => {
//       const list = [];
//       fsData.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
//       this.$logger('info', [`[action] getProblems`, list]);
//       commit('updateTicketsState', {
//         state: 'tickets',
//         data: list
//       });
//     })
//     .catch((err) => {
//       this.$logger('error', ['[action] getProblems', err]);
//     });
// }

// export function getCreatedTickets({ commit }, payload) {
//   return this.$fs
//     .collection('tickets')
//     .where('createdBy', '==', payload)
//     .get()
//     .then((fsData) => {
//       const list = [];
//       fsData.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
//       this.$logger('info', [`[action] getCreatedTickets`, list]);
//       commit('updateTicketsState', {
//         state: 'ownProblems',
//         data: list
//       });
//     })
//     .catch((err) => {
//       this.$logger('error', ['[action] getCreatedTickets', err]);
//     });
// }

// New from here
export function createTicket({ dispatch }, payload) {
  return this.$fs
    .collection('tickets')
    .add(payload)
    .then(() => {
      dispatch('getCreatedTickets', this.$auth.currentUser.uid);
      this.$logger('info', [`[action] createTicket`, payload.id]);
    })
    .catch((err) => {
      this.$logger('error', ['[action] createTicket', err]);
    });
}

export function updateTicket({ dispatch }, payload) {
  return this.$fs
    .collection('tickets')
    .doc(payload.id)
    .update(payload)
    .then(() => {
      dispatch('getTickets');
      this.$logger('info', [`[action] updateTicket`, payload.id]);
    })
    .catch((err) => {
      this.$logger('error', ['[action] updateTicket', err]);
    });
}

export function deleteTicket({ dispatch }, payload) {
  return this.$fs
    .collection('tickets')
    .doc(payload.id)
    .delete()
    .then(async () => {
      const urlsToDelete = [];
      if (payload.media) {
        for (const image of payload.media) {
          urlsToDelete.push(image);
        }
      }
      if (payload.notesMedia) {
        for (const image of payload.notesMedia) {
          urlsToDelete.push(image);
        }
      }
      //perform delete operations
      for (const url of urlsToDelete) {
        await this.$stor.refFromURL(url).delete();
      }
      return;
    })
    .then(async () => {
      this.$logger('info', [`[action] deleteTicket`, payload]);
      await dispatch('getTickets');
      await dispatch('getCreatedTickets', payload.createdBy);
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      console.error(err);
      this.$logger('error', ['[action] deleteTicket', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function getTickets({ commit }, payload) {
  return this.$fs
    .collection('tickets')
    .get()
    .then((fsData) => {
      const list = [];
      fsData.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
      this.$logger('info', [`[action] getTickets`, list]);
      commit('updateTicketsState', {
        state: 'tickets',
        data: list
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getTickets', err]);
    });
}

export function getCreatedTickets({ commit }, payload) {
  return this.$fs
    .collection('tickets')
    .where('createdBy', '==', payload)
    .get()
    .then((fsData) => {
      const list = [];
      fsData.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
      this.$logger('info', [`[action] getCreatedTickets`, list]);
      commit('updateTicketsState', {
        state: 'ownProblems',
        data: list
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getCreatedTickets', err]);
    });
}

export function getTicketsByCategory({ commit }, payload) {
  return this.$fs
    .collection('tickets')
    .where('category', '==', payload)
    .where('status', 'in', ['new', 'inProgress'])
    .get()
    .then((fsData) => {
      const list = [];
      fsData.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
      return list;
    });
}
//TODO very broken
export function getDeadline({ commit }) {
  return this.$fs
    .collection('ticketDeadline')
    .doc('deadline')
    .get()
    .then((doc) => {
      commit('updateTicketsState', {
        state: 'deadline',
        data: doc.data()
      });
      return doc.data();
    })
    .catch((err) => {
      this.$logger('error', ['[action] getDeadline', err]);
    });
}

export function updateDeadline({ dispatch }, payload) {
  return this.$fs
    .collection('ticketDeadline')
    .doc('deadline')
    .set({ days: +payload }, { merge: true })
    .then(() => {
      dispatch('getDeadline');
      this.$logger('info', [`[action] getDeadline`, payload]);
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] updateDeadline', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}
