<template>
  <div id="q-app">
    <router-view />
    <AppLink v-if="!$q.platform.is.cordova" />
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import { cordovaInit } from './mixins/CordovaInit';
import AppLink from 'components/ui/AppLink';
export default {
  name: 'App',
  components: {
    AppLink
  },
  mixins: [cordovaInit],
  provide() {
    return {
      langEnabled: !process.env.IMPULSE.DISABLE_LANGS,
      clientUrl: process.env.DEV
        ? 'http://localhost:8080'
        : process.env.IMPULSE.CLIENT_URL
    };
  },
  computed: {
    ...mapState('core', ['initComplete'])
  },
  methods: {
    ...mapActions('core', ['initApp']),
    ...mapMutations('admin', ['updateNetworkInfo']),
    setLang(lang) {
      window.localStorage.setItem('ImpulselangPref', lang);
      this.$i18n.locale = lang;
      import(
        // 17-03-21 Phil Lane: Include other languages here as added to i18n
        /* webpackInclude: /(de|en-us|pl|en-gb)\.js$/ */
        'quasar/lang/' + lang
      ).then((lang) => {
        this.$q.lang.set(lang.default);
      });
    }
  },
  mounted() {
    if (this.$q.platform.is.cordova) {
      this.cordovaInit();
    }

    if(this.$q.platform.is.cordova) {
      console.log('REMOVING SPLASH', navigator.splashscreen);
      navigator.splashscreen.hide();

    }

    if (!this.initComplete && this.$auth.currentUser) {
      console.log('initialising app');
      this.initApp();
    }
    const langPref = window.localStorage.getItem('ImpulselangPref');
    if (langPref) {
      this.setLang(langPref);
    }
    return;
  },
  created() {}
};
</script>
