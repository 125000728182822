// export const getGrades = (state) => {
//   const gradesCopy = [...new Set(state.grades)];
//   return gradesCopy;
// };

export function userPickList(state, getters, rootState) {
  return rootState.user.users
    .map((x) => {
      return { id: x.uid, name: `${x.first} ${x.last}`, type: 'user' };
    })
    .sort();
}

export function gradesPickList(state) {
  return state.grades
    .map((x) => {
      return { ...x, type: 'grade' };
    })
    .sort();
}

export function departmentsPickList(state) {
  return state.flatStructure
    .map((x) => {
      return { ...x, type: 'department' };
    })
    .sort();
}

export const sitesPickList = (state, getters, rootState) => (locale) => {
  return rootState.sites;
  return rootState.sites.sites
    .map((x) => {
      return {
        id: x.uid,
        name: x.title[locale].text,
        type: 'site'
      };
    })
    .sort();
};

export function commentsRequiringModeration(state, getters, rootState) {
  const articleComments = rootState.articles.reportedComments;
  const eventComments = rootState.events.reportedComments;
  const userContent = rootState.articles.reportedContent;
  const combinedComments = [...articleComments, ...eventComments, ...userContent];
  return combinedComments;
}

export const getSingleRole = (state) => (id) => {
  return state.roles.find((x) => x.id === id);
};
