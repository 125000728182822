import { FS, FS2 } from '../../boot/firebase';
import _ from 'lodash';
import { data } from 'currency-codes';

// 17-03-21 Phil Lane: This is the template for all actions to populate state from Firestore.
export function listPermissions({ commit }) {
  return this.$fs
    .collection('config')
    .doc('permissions')
    .get()
    .then((fsData) => {
      this.$logger('info', [`[action] listPermissions`, fsData.data()]);
      commit('updateAdminState', {
        state: 'perms',
        data: fsData.data()
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] listPermissions', err]);
    });
}

export function listFeatures({ commit }) {
  return this.$fs
    .collection('config')
    .doc('features')
    .get()
    .then((fsData) => {
      this.$logger('info', [`[action] listFeatures`, fsData.data()]);
      commit('updateAdminState', {
        state: 'features',
        data: fsData.data()
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] listFeatures', err]);
    });
}

export function getLicenseDetails({ commit }) {
  return this.$fs
    .collection('config')
    .doc('license')
    .get()
    .then((fsData) => {
      this.$logger('info', [`[action] getLicenseDetails`, fsData.data()]);
      commit('updateAdminState', {
        state: 'license',
        data: fsData.data()
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getLicenseDetails', err]);
    });
}

// export function getProblems({ commit }) {
//   return this.$fs
//     .collection('tickets')
//     .orderBy('created', 'desc')
//     .get()
//     .then((fsData) => {
//       const list = [];
//       fsData.forEach((doc) => {
//         list.push({ uid: doc.id, ...doc.data() });
//       });
//       this.$logger('info', [`[action] getProblems`, list]);
//       commit('updateAdminState', {
//         state: 'tickets',
//         data: list
//       });
//     })
//     .catch((err) => {
//       this.$logger('error', ['[action] getProblems', err]);
//     });
// }

export function getWorkStatuses({ commit }) {
  return this.$fs
    .collection('userStatuses')
    .get()
    .then((fsData) => {
      const list = [];
      fsData.forEach((doc) => {
        list.push({ uid: doc.id, ...doc.data() });
      });
      this.$logger('info', [`[action] getWorkStatuses`, list]);
      commit('updateAdminState', {
        state: 'userStatuses',
        data: list
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getWorkStatuses', err]);
    });
}

export function getUserGrades({ commit }) {
  return this.$fs
    .collection('userGrades')
    .orderBy('level')
    .get()
    .then((fsData) => {
      const list = [];
      fsData.forEach((doc) => {
        list.push({ uid: doc.id, ...doc.data() });
      });
      this.$logger('info', [`[action] getUserGrades`, list]);
      commit('updateAdminState', {
        state: 'grades',
        data: list
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getUserGrades', err]);
    });
}

// export function getSites({ commit }) {
//   return this.$fs
//     .collection('sites')
//     .orderBy('title', 'asc')
//     .get()
//     .then((fsData) => {
//       const list = [];
//       fsData.forEach((doc) => {
//         list.push({ uid: doc.id, ...doc.data() });
//       });
//       this.$logger('info', [`[action] getSites`, list]);
//       commit('updateAdminState', {
//         state: 'sites',
//         data: list
//       });
//     })
//     .catch((err) => {
//       this.$logger('error', ['[action] getSites', err]);
//     });
// }

export function getTicketCategories({ commit }) {
  return this.$fs
    .collection('ticketCategories')
    .orderBy('createdAt')
    .get()
    .then((fsData) => {
      const ticketCategories = [];
      fsData.forEach((doc) => {
        ticketCategories.push({ uid: doc.id, ...doc.data() });
      });
      this.$logger('info', [`[action] getTicketCategories`, ticketCategories]);
      commit('updateAdminState', {
        state: 'ticketCategories',
        data: ticketCategories
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getTicketCategories', err]);
    });
}

export function getStructure({ commit }) {
  return this.$fs
    .collection('structure')
    .get()
    .then((result) => {
      const flatStructure = [];
      result.docs.forEach((doc) => {
        flatStructure.push(doc.data());
      });
      commit('updateAdminState', {
        state: 'flatStructure',
        data: _.cloneDeep(flatStructure)
      });
      return flatStructure;
    })
    .then((flatStructure) => {
      const structure = [];
      if (!flatStructure.length) {
        structure.push({ id: 'root', name: '', children: [] });
      }
      flatStructure.forEach((node) => {
        if (!node.parent) return (structure[0] = node);
        const parentIndex = flatStructure.findIndex(
          (el) => el.id === node.parent
        );
        if (!node.children) node.children = [];
        if (!flatStructure[parentIndex].children) {
          return (flatStructure[parentIndex].children = [node]);
        }
        flatStructure[parentIndex].children.push(node);
      });
      return structure;
    })
    .then((structure) => {
      this.$logger('info', [`[action] getStructure`, structure]);
      commit('updateAdminState', {
        state: 'structure',
        data: structure
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getStructure', err]);
    });
}

export function getChannels({ commit }) {
  return this.$fs
    .collection('channels')
    .orderBy('label')
    .get()
    .then((fsData) => {
      const channels = [];
      fsData.forEach((doc) => {
        channels.push({ id: doc.id, ...doc.data() });
      });
      this.$logger('info', [`[action] getChannels`, channels]);
      commit('updateAdminState', {
        state: 'channels',
        data: channels
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getChannels', err]);
    });
}

export function getSuggestionRecipient({ commit }) {
  return this.$fs
    .collection('config')
    .doc('suggestions')
    .get()
    .then((fsData) => {
      this.$logger('info', [`[action] getSuggestionRecipient`, fsData.data()]);
      commit('updateAdminState', {
        state: 'suggestionRecipient',
        data: fsData.data().recipient
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getSuggestionRecipient', err]);
    });
}

export async function getTicketAdmins({ commit }) {
  const ticketAdmins = await this.$func.httpsCallable(
    'tickets-queryTicketAdmins'
  )();
  commit('updateAdminState', {
    state: 'ticketAdmins',
    data: ticketAdmins.data
  });
  return ticketAdmins;
}

export async function getTicketUsers({ commit }) {
  const ticketUsers = await this.$func.httpsCallable(
    'tickets-queryTicketUsers'
  )();
  commit('updateAdminState', {
    state: 'ticketUsers',
    data: ticketUsers.data
  });
}

export async function getTicketAdminsAndUsers({ commit }) {
  const ticketAdminsAndUsers = await this.$func.httpsCallable(
    'tickets-queryTicketUsersAndAdmins'
  )();

  console.log(ticketAdminsAndUsers);
  commit('updateAdminState', {
    state: 'ticketUsers',
    data: ticketAdminsAndUsers.data.ticketUsers
  });
  commit('updateAdminState', {
    state: 'ticketAdmins',
    data: ticketAdminsAndUsers.data.ticketAdmins
  });
}

export async function getSuperUsers({ commit }) {
  const superUsers = await this.$func.httpsCallable('claims-querySuperUsers')();
  commit('updateAdminState', {
    state: 'superUsers',
    data: superUsers.data
  });
  return superUsers.data;
}

export function getLocales({ commit }) {
  return this.$fs
    .collection('config')
    .doc('locales')
    .get()
    .then((fsData) => {
      this.$logger('info', [`[action] getLocales`, fsData.data()]);
      if (fsData.data()) {
        commit('updateAdminState', {
          state: 'locales',
          data: fsData.data()
        });
      }
    })
    .catch((err) => {
      this.$logger('error', ['[action] getLocales', err]);
    });
}

export function dismissModeration(
  { dispatch },
  { collection, comment, dismissedBy }
) {
  return this.$fs
    .collection(collection)
    .doc(comment)
    .update({
      requiresModeration: false,
      dismissedBy,
      dissmissedAt: new Date()
    })
    .then(async () => {
      this.$logger('info', [`[action] dismissModeration`, comment]);
      await dispatch('events/getReportedEventComments', null, {
        root: true
      });
      await dispatch('articles/getReportedComments', null, {
        root: true
      });
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] dismissModeration', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function upholdModeration(
  { dispatch },
  { collection, comment, moderatedBy }
) {
  return this.$fs
    .collection(collection)
    .doc(comment)
    .update({
      requiresModeration: false,
      removedByModerator: true,
      moderatedBy,
      moderatedAt: new Date()
    })
    .then(async () => {
      this.$logger('info', [`[action] upholdModeration`, comment]);
      await dispatch('events/getReportedEventComments', null, {
        root: true
      });
      await dispatch('articles/getReportedComments', null, {
        root: true
      });
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] upholdModeration', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function getWelcomeNotice({ commit }) {
  return this.$fs
    .collection('setup')
    .doc('welcomeNotice')
    .get()
    .then((fsData) => {
      this.$logger('info', [`[action] getWelcomeNotice`, fsData.data()]);
      if (fsData.exists) {
        commit('updateAdminState', {
          state: 'welcomeNotice',
          data: fsData.data()
        });
      }
    })
    .catch((err) => {
      this.$logger('error', ['[action] getWelcomeNotice', err]);
    });
}

export function updateWelcomeNotice({ dispatch }, payload) {
  return this.$fs
    .collection('setup')
    .doc('welcomeNotice')
    .set(payload, { merge: true })
    .then(async () => {
      this.$logger('info', [`[action] updateWelcomeNotice`, payload]);
      dispatch('getWelcomeNotice');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] updateWelcomeNotice', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function deleteWorkStatus({ dispatch }, payload) {
  this.$fs
    .collection('userStatuses')
    .doc(payload)
    .delete()
    .then(() => {
      this.$logger('info', [`[action] deleteWorkStatus`, payload]);
      dispatch('getWorkStatuses');
    })
    .catch((err) => {
      this.$logger('error', ['[action] deleteWorkStatus', err]);
    });
}

export function createWorkStatus({ dispatch }, payload) {
  return this.$fs
    .collection('userStatuses')
    .add(payload)
    .then(async () => {
      this.$logger('info', [`[action] createWorkStatus`, payload]);
      await dispatch('getWorkStatuses');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] createWorkStatus', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}
export function updateWorkStatus({ dispatch }, payload) {
  return this.$fs
    .collection('userStatuses')
    .doc(payload.uid)
    .update(payload)
    .then(async () => {
      this.$logger('info', [`[action] updateWorkStatus`, payload]);
      await dispatch('getWorkStatuses');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] updateWorkStatus', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

//TODO 17-03-21 Phil Lane: Move this to part of DB initial config to make this unnessesary.
// export const createOtherCat = async ({ commit }) => {
//   const other = {
//     createdAt: FS2.FieldValue.serverTimestamp(),
//     title: 'Other',
//     user: ''
//   };
//   const cats = [];
//   await FS.collection('ticketCategories')
//     .get()
//     .then((snap) => {
//       snap.forEach((doc) => {
//         cats.push({ id: doc.id, ...doc.data() });
//       });
//     });

//   if (!cats.filter((x) => x.title === 'Other').length) {
//     return FS.collection('ticketCategories')
//       .add(other)
//       .then(() => commit('createOtherCat', other))
//       .catch((err) => console.log('ERROR CREATE OTHER CAT', err));
//   }
// };

// TODO 17-03-21 Phil Lane: does this need refactoring as we currently only have DAU analytics, perhaps move to separate Analyics store
export const getAnalytics = async ({ commit }, type) => {
  FS.collection('analytics')
    .doc(type)
    .onSnapshot(
      (snap) => {
        commit('updateAnalytics', { type, data: snap.data() });
      },
      (err) => {
        console.error('[action] getAnalytics', err);
      }
    );
};

export async function getUserClaims({ commit, dispatch }, uid) {
  const queryUserClaims = this.$func.httpsCallable('claims-queryUserClaims');
  return await queryUserClaims({ uid }).then((claims) => {
    commit('updateAdminState', {
      state: 'userClaims',
      data: claims.data.result
    });
    return claims.data.result.roles;
  });
}

export async function createUser({ dispatch }, payload) {
  const createUser = this.$func.httpsCallable('createUser-createUser');

  return await createUser(payload);
}

export async function updateUser({ dispatch }, payload) {
  const updateUser = this.$func.httpsCallable('updateUser-updateUserDetails');

  return await updateUser(payload);
}

export async function bulkRoleAssign({ dispatch }, payload) {
  const addRoles = this.$func.httpsCallable('updateUser-updateUserRoles');
  for (const update of payload.data) {
    await addRoles(update);
  }
  return true;
}

export function getRoles({ commit, dispatch }) {
  return this.$fs
    .collection('roles')
    .get()
    .then((fsData) => {
      const list = [];
      fsData.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });

      commit('updateAdminState', {
        state: 'roles',
        data: list
      });
      this.$logger('info', [`[action] getRoles`]);
      return list;
    })

    .catch((err) => {
      this.$logger('error', ['[action] getRoles', err]);
    });
}
export function createRole({ commit, dispatch }, payload) {
  return this.$fs
    .collection('roles')
    .add(payload)
    .then(async () => {
      this.$logger('info', [`[action] createRole`, payload]);
      await dispatch('getRoles');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] createRole', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}
export function updateRole({ dispatch }, payload) {
  return this.$fs
    .collection('roles')
    .doc(payload.id)
    .update(payload)
    .then(async () => {
      this.$logger('info', [`[action] updateRole`, payload]);
      await dispatch('getRoles');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] updateRole', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

//TODO 14-04-21 Phil Lane: Clear stale roles from user on delete role.
export function deleteRoles({ dispatch }, payload) {
  const deleteActions = [];

  const deleteAction = (id) => this.$fs.collection('roles').doc(id).delete();

  for (const role of payload) {
    deleteActions.push(deleteAction(role));
  }

  return Promise.all(deleteActions)
    .then(async () => {
      await dispatch('getRoles');
      dispatch('core/popToast', 'positive', {
        root: true
      });
      this.$logger('info', [`[action] deleteRoles`, payload]);
    })
    .catch((err) => {
      this.$logger('error', ['[action] deleteRoles', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export async function getFormAdmins({ commit }) {
  const formAdmins = await this.$func.httpsCallable(
    'forms-queryFormApprovers'
  )();
  commit('updateAdminState', {
    state: 'formAdmins',
    data: formAdmins.data
  });
  return formAdmins;
}

export async function getRolesSummary({ commit }) {
  const rolesSummary = await this.$func.httpsCallable(
    'claims-updateRolesAppliedCount'
  )();
  commit('updateAdminState', {
    state: 'roleCounts',
    data: _.countBy(rolesSummary.data.flat().filter((x) => !!x))
  });
  return _.countBy(rolesSummary.data.flat().filter((x) => !!x));
}
