export const userEvents = (state) => (id) => {
  const userEvents = state.events.filter(
    (x) => x.invitees.includes(id) || x.createdBy === id
  );
  return userEvents.filter((event) => {
    const now = new Date();
    //event.timeEnd.substring(0,2));//hours
    //event.timeEnd.substring(3,5));//minutes
    return event.dateEnd
      ? new Date(
          event.dateEnd
            .toDate()
            .setHours(
              +event.timeEnd.substring(0, 2),
              +event.timeEnd.substring(3, 5)
            )
        ) >= now
      : new Date(
          event.dateStart
            .toDate()
            .setHours(
              +event.timeStart.substring(0, 2),
              +event.timeStart.substring(3, 5)
            )
        ) >= now;
  });
};

export const eventComments = (state) => (id) => {
  return state.eventComments
    .filter((x) => x.eventId === id)
    .filter((x) => !x.deleted)
    .filter((x) => !x.removedByModerator);
};
