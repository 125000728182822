import Vue from 'vue';
import VueQrcode from '@chenfengyuan/vue-qrcode';


Vue.component(VueQrcode.name, VueQrcode);


const logger = (type, data) => {
  if (!process.env.DEV || type === 'error') {
    console[type](data);
  }
};

Vue.prototype.$logger = logger;

export default ({ store }) => {
  store.$logger = logger;
};

export { logger };
