import { Loading, Platform, Notify } from 'quasar';

export async function login({ commit, dispatch, state }, payload) {
  return this.$auth
    .signInWithEmailAndPassword(payload.email, payload.password)
    .then(async (firebaseUser) => {
      //TODO Is this used anywhere? if so refactor mutation
      // store the users uid in state
      // commit('updateUser', firebaseUser.user.uid);

      if (Platform.is.cordova) {
        await this.$fs
          .collection('users')
          .doc(firebaseUser.user.uid)
          .update({
            devices: this.$fs2.FieldValue.arrayUnion(state.deviceID)
          })
          .catch((err) => {
            console.error(err);
          });
      }

      dispatch('core/initApp', null, { root: true }).then(() => {
        this.$router.push('/');
        this.$fs
          .collection('users')
          .doc(firebaseUser.user.uid)
          .get()
          .then((user) => {
            if (!user.data().firstSignIn) {
              this.$fs
                .collection('users')
                .doc(firebaseUser.user.uid)
                .update({
                  firstSignIn: this.$fs2.FieldValue.serverTimestamp()
                });
            } else {
              this.$fs
                .collection('users')
                .doc(firebaseUser.user.uid)
                .update({
                  lastSignIn: this.$fs2.FieldValue.serverTimestamp()
                });
            }
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            Loading.hide();
          });
      });
    })
    .catch((error) => {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      console.error(errorCode, errorMessage);
      Notify.create({
        type: 'negative',
        message: errorMessage
      });
      Loading.hide();
      // ...
    });
}

// export function changePassword(context, payload) {
//   AUTH.signInWithEmailAndPassword(payload.email, payload.password)
//     .then((userCredential) => userCredential.user.updateEmail(payload.newEmail))
//     .then((creds) => {
//       console.log('CREDS', creds)
//     })
//     .catch((error) => console.error(error))
// }

export function logout() {
  return this.$auth
    .signOut()
    .then(() => {
      return this.$router.push('/login');
    })
    .catch((err) => {
      this.$logger('error', ['[action] createSite', err]);
    });
}

export function getUsers({ commit }) {
  return (
    this.$fs
      .collection('users')
      // .where('deleted', '!=', true)
      // .orderBy('deleted')
      .orderBy('first')
      .get()
      .then((fsData) => {
        const list = [];
        fsData.forEach((doc) => {
          list.push({ uid: doc.id, ...doc.data() });
        });
        this.$logger('info', [`[action] getUsers`, list]);
        commit('updateUserState', {
          state: 'users',
          data: list.filter((x) => !x.deleted)
        });
        commit('updateUserState', {
          state: 'currentUser',
          data: {
            ...list.find((user) => user.uid === this.$auth.currentUser.uid),
            uid: this.$auth.currentUser.uid
          }
        });
        return this.$auth.currentUser.uid;
      })
      .catch((err) => {
        this.$logger('error', ['[action] getUsers', err]);
      })
  );
}

export function getUser({ commit }, payload) {
  return this.$fs
    .collection('users')
    .doc(payload)
    .get()
    .then((fsData) => {
      this.$logger('info', [`[action] getUser`, payload]);
      commit('updateUserState', {
        state: 'viewedUser',
        data: fsData.exists ? { uid: fsData.id, ...fsData.data() } : {}
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getUser', err]);
    });
}

export function updateUser({ dispatch }, payload) {
  // single prop update only
  let update;
  for (const prop in payload) {
    if (prop !== 'id') {
      update = prop;
    }
  }
  this.$logger('info', [
    `[action] updateUser`,
    {
      [`${update}`]: payload[update]
    }
  ]);
  return this.$fs
    .collection('users')
    .doc(payload.id)
    .update({
      [`${update}`]: payload[update]
    })
    .then(async () => {
      await dispatch('getUsers');
    })
    .catch((err) => {
      this.$logger('error', ['[action] updateUser', err]);
    });
}

export async function bulkUserUpdate({ dispatch }, payload) {
  for (let i = 0; i < payload.update.length; i++) {
    const key = Object.keys(payload.update[i])[0];
    await this.$fs
      .collection('users')
      .doc(payload.id)
      .update({
        [`${key}`]: payload.update[i][`${key}`]
      })
      .then(async () => {
        await dispatch('getUsers');
      })
      .catch((err) => {
        this.$logger('error', ['[action] bulkUserUpdate', err]);
      });
  }
}

// export function getUser({ commit }, payload) {
//   return FS.collection('users')
//     .doc(payload)
//     .get()
//     .then((doc) => {
//       let user = { id: doc.id, ...doc.data() };
//       commit('updateViewedUser', user);
//     });
// }

//TODO refactor
export function updateDAU(context, payload) {
  this.$fs
    .collection('analytics')
    .doc('dau')
    .get()
    .then((doc) => {
      if (!doc.exists) {
        this.$fs
          .collection('analytics')
          .doc('dau')
          .set({});
      }
    })
    .then(() => {
      return this.$fs
        .collection('analytics')
        .doc('dau')
        .update({
          [new Date().toDateString()]: this.$fs2.FieldValue.arrayUnion(payload)
        });
    })
    .catch((err) => {
      this.$logger('error', ['[action] updateDAU', err]);
    });
}

export function getLearning({ commit }, payload) {
  this.$fs
    .collection('users')
    .doc(payload.user)
    .collection('learning')
    .get()
    .then((docs) => {
      const userLearning = [];
      docs.forEach((doc) => {
        userLearning.push({ id: doc.id, ...doc.data() });
      });
      if (!payload.embedded) {
        commit('updateUserLearning', userLearning);
      } else {
        commit('updateTeamLearning', {
          data: userLearning,
          user: payload.user
        });
      }
    })
    .catch((err) => {
      console.error('[action] getLearning', err);
    });
}

export function changeSearch({ commit }, payload) {
  commit('changeSearch', payload);
}

export function updateUserWorkStatus({ dispatch }, payload) {
  this.$fs
    .collection('users')
    .doc(this.$auth.currentUser.uid)
    .update({
      status: payload.uid
    })
    .then(() => {
      this.$logger('info', [`[action] updateUserWorkStatus`, payload]);
      dispatch('getUser', this.$auth.currentUser.uid);
    })
    .catch((err) => {
      this.$logger('error', ['[action] updateUserWorkStatus', err]);
    });
}

export function getTeam({ commit }, payload) {
  return this.$fs
    .collection('users')
    .where('reportsTo', '==', this.$auth.currentUser.uid)
    .orderBy('first')
    .get()
    .then((snap) => {
      const list = [];
      snap.docs.forEach((x) => {
        list.push(x.data());
      });
      this.$logger('info', [`[action] getTeam`, payload]);
      commit('updateUserState', {
        state: 'team',
        data: list.filter((x) => !x.deleted)
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getTeam', err]);
    });
}

export async function addFCMDeviceToken({ state }, payload) {
  if (!state.currentUser.uid) return;

  return await this.$fs
    .collection('users')
    .doc(state.currentUser.uid)
    .update({
      devices: this.$fs2.FieldValue.arrayUnion(payload)
    })
    .then(() => {
      return true;
    })
    .catch((err) => {
      console.error(err);
    });
}
