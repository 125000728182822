const routes = [
  {
    path: '/no-permission',
    component: () => import('pages/errors/NoPermission.vue')
  },
  {
    path: '/login',
    component: () => import('layouts/PreAuth.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/auth/Login.vue'),
        meta: { requiresAuth: false }
      },
      {
        path: 'confirm-email',
        component: () => import('pages/auth/ConfirmEmail.vue'),
        meta: { requiresAuth: false }
      },
      {
        path: 'finish-signup',
        component: () => import('pages/auth/FinishSignup.vue'),
        meta: { requiresAuth: false }
      },
      {
        path: 'with-phone',
        component: () => import('pages/auth/LoginWithPhone.vue'),
        meta: { requiresAuth: false }
      }
    ]
  },
  {
    path: '/admin',
    component: () => import('layouts/Default.vue'),
    meta: { requiresAuth: true, requiresAdmin: true },
    children: [
      {
        path: '',
        component: () => import('pages/Admin.vue'),
        children: [
          {
            path: '/',
            component: () => import('pages/admin/Index.vue')
          },
          {
            path: 'insights',
            component: () => import('pages/admin/Index.vue'),
            meta: {
              adminPerm: 'insights'
            }
          },
          {
            path: 'welcome',
            component: () => import('pages/admin/WelcomeNotice'),
            meta: {
              adminPerm: 'setup'
            }
          },
          {
            path: 'articles',
            component: () => import('pages/admin/articles/Index'),
            meta: {
              adminPerm: 'articles',
              feature: 'articles'
            }
          },

          {
            path: 'articles/edit-defaults',
            component: () => import('pages/admin/articles/DefaultImageEditor'),
            meta: {
              adminPerm: 'articles',
              feature: 'articles'
            }
          },
          {
            path: 'articles/edit-channels',
            component: () => import('pages/admin/articles/ChannelEditor'),
            meta: {
              adminPerm: 'articles',
              feature: 'articles'
            }
          },
          {
            path: 'articles/:id',
            component: () => import('pages/admin/articles/ArticleEditor'),
            meta: {
              adminPerm: 'articles',
              feature: 'articles'
            }
          },
          {
            path: 'linkedarticles/:id',
            component: () => import('pages/admin/articles/LinkedArticleEditor'),
            meta: {
              adminPerm: 'articles',
              feature: 'articles'
            }
          },
          {
            path: 'surveys',
            component: () => import('pages/admin/surveys/Index'),
            meta: {
              adminPerm: 'surveys',
              feature: 'surveys'
            }
          },
          {
            path: 'surveys/:id',
            component: () => import('pages/admin/surveys/SurveyEditor'),
            meta: {
              adminPerm: 'surveys',
              feature: 'surveys'
            }
          },
          {
            path: 'surveys/results/:id',
            component: () => import('pages/admin/surveys/SurveyResults'),
            meta: {
              adminPerm: 'surveys',
              feature: 'surveys'
            }
          },
          {
            path: 'polls',
            component: () => import('pages/admin/polls/Index'),
            meta: {
              adminPerm: 'polls',
              feature: 'polls'
            }
          },
          {
            path: 'polls/:id',
            component: () => import('pages/admin/polls/PollEditor'),
            meta: {
              adminPerm: 'polls',
              feature: 'polls'
            }
          },
          {
            path: 'polls/results/:id',
            component: () => import('pages/admin/polls/PollResults'),
            meta: {
              adminPerm: 'polls',
              feature: 'polls'
            }
          },
          {
            path: 'broadcasts',
            component: () => import('pages/admin/broadcasts/Index'),
            meta: {
              adminPerm: 'broadcast',
              feature: 'broadcast'
            }
          },
          {
            path: 'broadcasts/:id',
            component: () => import('pages/admin/broadcasts/BroadcastEditor'),
            meta: {
              adminPerm: 'broadcast',
              feature: 'broadcast'
            }
          },
          {
            path: 'roles',
            component: () => import('pages/admin/users/RolesTable'),
            meta: {
              // adminPerm: 'users'
            }
          },
          {
            path: 'roles/:id',
            component: () => import('pages/admin/users/RolesEditor'),
            meta: {
              // adminPerm: 'users'
            }
          },
          {
            path: 'roleAssigned/:id',
            component: () => import('pages/admin/users/RoleAssigned'),
            meta: {
              // adminPerm: 'users'
            }
          },
          {
            path: 'users',
            component: () => import('pages/admin/UsersAdmin'),
            meta: {
              adminPerm: 'users'
            }
          },
          {
            path: 'users/statuses',
            component: () => import('pages/admin/users/UserStatusesEditor'),
            meta: {
              adminPerm: 'users'
            }
          },
          {
            path: 'users/import',
            component: () => import('pages/admin/users/UserImport'),
            meta: {
              adminPerm: 'users'
            }
          },
          {
            path: 'users/:id',
            component: () => import('pages/admin/users/UserEditor'),
            meta: {
              adminPerm: 'users'
            }
          },
          {
            path: 'bulkassign',
            component: () => import('pages/admin/users/BulkAssign'),
            meta: {
              adminPerm: 'users'
            }
          },
          {
            path: 'bulkedit',
            component: () => import('pages/admin/users/BulkUserEditor'),
            meta: {
              adminPerm: 'users'
            }
          },
          {
            path: 'menus',
            component: () => import('pages/admin/menus/Index'),
            meta: {
              adminPerm: 'menus',
              feature: 'menus'
            }
          },
          {
            path: 'menus/:id',
            component: () => import('pages/admin/menus/MenuEditor'),
            meta: {
              adminPerm: 'menus',
              feature: 'menus'
            }
          },
          {
            path: 'setup',
            component: () => import('pages/admin/Setup'),
            meta: {
              adminPerm: 'setup'
            }
          },
          {
            path: 'sites',
            component: () => import('pages/admin/sites/Index'),
            meta: {
              adminPerm: 'sites'
            }
          },
          {
            path: 'sites/:id',
            component: () => import('pages/admin/sites/SiteEditor'),
            meta: {
              adminPerm: 'sites'
            }
          },
          {
            path: 'tasks',
            component: () => import('pages/admin/Task')
          },
          {
            path: 'vacancies',
            component: () => import('pages/admin/vacancies/Index'),
            meta: {
              adminPerm: 'vacancies',
              feature: 'vacancies'
            }
          },
          {
            path: 'vacancies/:id',
            component: () => import('pages/admin/vacancies/VacancyEditor'),
            meta: {
              adminPerm: 'vacancies',
              feature: 'vacancies'
            }
          },
          {
            path: 'library',
            component: () => import('pages/admin/library/Index'),
            meta: {
              adminPerm: 'library',
              feature: 'library'
            }
          },
          {
            path: 'library/:id',
            component: () => import('pages/admin/library/LibraryEditor'),
            meta: {
              adminPerm: 'library',
              feature: 'library'
            }
          },

          {
            path: 'libraryfolders/:id',
            component: () => import('pages/admin/library/LibraryFolderEditor'),
            meta: {
              adminPerm: 'library',
              feature: 'library'
            }
          },
          {
            path: 'learning',
            component: () => import('pages/admin/Learning/Index'),
            meta: {
              adminPerm: 'learning',
              feature: 'learning'
            }
          },
          {
            path: 'learning/learning-categories',
            component: () =>
              import('pages/admin/Learning/LearningCategoryEditor'),
            meta: {
              adminPerm: 'learning',
              feature: 'learning'
            }
          },
          {
            path: 'learning/issue-course/:id',
            component: () => import('pages/admin/Learning/IssueCourse'),
            meta: {
              adminPerm: 'learning',
              feature: 'learning'
            }
          },
          {
            path: 'learning/learning-results/:id',
            component: () => import('pages/admin/Learning/Results'),
            meta: {
              adminPerm: 'learning',
              feature: 'learning'
            }
          },
          {
            path: 'learning/user-records',
            component: () => import('pages/admin/Learning/LearningUserRecords'),
            meta: {
              requiresAuth: true,
              userPerm: 'learning',
              feature: 'learning'
            }
          },
          {
            path: 'learning/user-records/:id',
            component: () => import('pages/admin/Learning/UserRecord'),
            meta: {
              requiresAuth: true,
              userPerm: 'learning',
              feature: 'learning'
            }
          },
          {
            path: 'learning/:id',
            component: () => import('pages/admin/Learning/LearningEditor'),
            meta: {
              adminPerm: 'learning',
              feature: 'learning'
            }
          },
          {
            path: 'insights',
            component: () => import('pages/admin/Insights/Index.vue'),
            children: [
              {
                path: 'users',
                component: () => import('pages/admin/insights/Users')
              },
              {
                path: 'comms',
                component: () => import('pages/admin/insights/Comms')
              },
              {
                path: 'sentiment',
                component: () => import('pages/admin/insights/Sentiment')
              },
              {
                path: 'impulse',
                component: () => import('pages/admin/insights/Impulse')
              },
              {
                path: 'forms',
                component: () => import('pages/admin/insights/Forms')
              },
              {
                path: 'tickets',
                component: () => import('pages/admin/insights/Tickets')
              }
            ]
          },
          {
            path: 'moderation',
            component: () => import('pages/admin/CommentModeration'),
            meta: {
              requiresAuth: true,
              adminPerm: 'moderation'
            }
          },
          {
            path: 'competitions',
            component: () => import('pages/admin/competitions/index'),
            meta: {
              adminPerm: 'competitions',
              feature: 'competitions'
            }
          },
          {
            path: 'competitions/:id',
            component: () =>
              import('pages/admin/competitions/CompetitionEditor'),
            meta: {
              adminPerm: 'competitions',
              feature: 'competitions'
            }
          },
          {
            path: 'form-templates',
            component: () => import('pages/admin/forms/index'),
            meta: {
              adminPerm: 'forms',
              feature: 'forms'
            }
          },
          {
            path: 'form-templates/:id',
            component: () => import('pages/admin/forms/FormEditor'),
            meta: {
              adminPerm: 'forms',
              feature: 'forms'
            }
          },
          {
            path: 'forms/:id',
            component: () => import('pages/admin/forms/UserForms'),
            meta: {
              adminPerm: 'forms',
              feature: 'forms'
            }
          },
          {
            path: 'forms',
            component: () => import('pages/admin/forms/TemplateList'),
            meta: {
              adminPerm: 'forms',
              feature: 'forms'
            }
          },
          {
            path: 'events',
            component: () => import('pages/admin/events/index'),
            meta: {
              adminPerms: 'events',
              feature: 'events'
            }
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: () => import('layouts/Default.vue'),
    children: [
      {
        path: '/',
        component: () => import('pages/Home.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'licences',
        component: () => import('pages/Licences.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'terms',
        component: () => import('pages/Terms.vue')
      },
      {
        path: 'profile/:id',
        component: () => import('pages/directory/Profile.vue'),
        meta: { requiresAuth: true }
      },
      // {
      //   name: 'PageDirectory',
      //   path: 'directory',
      //   component: () => import('pages/directory/Directory.vue'),
      //   meta: {
      //     requiresAuth: true,
      //     userPerm: 'directory',
      //     feature: 'directory'
      //   },
      //   props: true
      // },
      {
        name: 'PageDirectory',
        path: 'directory',
        component: () => import('pages/directory/Directory2.vue'),
        meta: {
          requiresAuth: true,
          userPerm: 'directory',
          feature: 'directory'
        },
        props: true
      },
      {
        path: 'articles',
        component: () => import('pages/articles/Articles.vue'),
        meta: {
          requiresAuth: true,
          userPerm: 'articles',
          feature: 'articles'
        }
      },
      
    
      {
        path: 'posts',
        component: () => import('pages/posts/Posts.vue'),
        meta: {
          requiresAuth: true,
          userPerm: 'articles',
          feature: 'articles'
        }
      },
      {
        path: 'posts/:id',
        component: () => import('pages/posts/Post.vue'),
        meta: {
          requiresAuth: true,
          userPerm: 'articles',
          feature: 'articles'
        }
      },
      {
        path: 'articles/:id',
        component: () => import('pages/articles/Article.vue'),
        meta: {
          requiresAuth: true,
          userPerm: 'articles',
          feature: 'articles'
        }
      },
      {
        path: 'events',
        component: () => import('pages/events/Events.vue'),
        meta: {
          requiresAuth: true,
          userPerm: 'events',
          feature: 'events'
        }
      },
      {
        path: 'events/:id',
        component: () => import('pages/events/Event.vue'),
        meta: {
          requiresAuth: true,
          userPerm: 'events',
          feature: 'events'
        }
      },

      {
        path: 'events/edit/:id',
        component: () => import('pages/events/EventEditor.vue'),
        meta: {
          requiresAuth: true,
          userPerm: 'events',
          adminPerm: 'events',
          feature: 'events'
        }
      },
      {
        path: 'competitions',
        component: () => import('pages/competitions/Competitions.vue'),
        meta: {
          requiresAuth: true,
          userPerm: 'competitions',
          feature: 'competitions'
        }
      },
      {
        path: 'competitions/:id',
        component: () => import('pages/competitions/Competition.vue'),
        meta: {
          requiresAuth: true,
          userPerm: 'competitions',
          feature: 'competitions'
        }
      },
      {
        path: 'competitions/redeem',
        name: 'redeem',
        component: () => import('pages/competitions/Redeem.vue'),

        meta: {
          requiresAuth: true,
          userPerm: 'competitions',
          feature: 'competitions'
        }
      },
      {
        path: 'scan',
        component: () => import('pages/competitions/Scan.vue'),
        meta: {
          requiresAuth: true,
          userPerm: 'competitions',
          feature: 'competitions'
        }
      },
      {
        path: 'updates',
        component: () => import('pages/Updates.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'chat',
        component: () => import('pages/chat/Index.vue'),
        meta: {
          requiresAuth: true,
          userPerm: 'chat',
          feature: 'chat'
        },
        children: [
          {
            path: '',
            component: () => import('pages/chat/Chats.vue'),
            meta: {
              requiresAuth: true,
              userPerm: 'chat',
              feature: 'chat'
            }
          },
          {
            path: 'new',
            component: () => import('pages/chat/NewChat.vue'),
            meta: {
              requiresAuth: true,
              userPerm: 'chat',
              feature: 'chat'
            }
          },
          {
            path: ':id/messages',
            component: () => import('pages/chat/Messages.vue'),
            meta: {
              requiresAuth: true,
              userPerm: 'chat',
              feature: 'chat'
            }
          }
        ]
      },
      {
        path: 'hr/vacancies',
        component: () => import('pages/vacancies/Vacancies'),
        meta: {
          requiresAuth: true,
          userPerm: 'vacancies',
          feature: 'vacancies'
        }
      },
      {
        path: 'hr/vacancies/:id',
        component: () => import('pages/vacancies/Vacancy'),
        meta: {
          requiresAuth: true,
          userPerm: 'vacancies',
          feature: 'vacancies'
        }
      },
      {
        path: 'menus',
        component: () => import('pages/menus/Menus'),
        meta: {
          requiresAuth: true,
          userPerm: 'menus',
          feature: 'menus'
        }
      },
      {
        path: 'menu/:id',
        component: () => import('pages/menus/Menu'),
        meta: {
          requiresAuth: true,
          userPerm: 'menus',
          feature: 'menus'
        }
      },
      {
        path: 'feedback',
        component: () => import('pages/tickets/FeedbackChoice'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'feedback/problem',
        component: () => import('pages/tickets/ReportProblem'),
        meta: {
          requiresAuth: true,
          userPerm: 'problems',
          feature: 'problems'
        }
      },
      {
        path: 'feedback/suggestion',
        component: () => import('pages/tickets/Suggestion'),
        meta: {
          requiresAuth: true,
          userPerm: 'suggestions',
          feature: 'suggestions'
        }
      },
      {
        path: 'feedback/list',
        component: () => import('pages/tickets/IssueList'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'polls',
        component: () => import('pages/polls/Polls'),
        meta: {
          requiresAuth: true,
          userPerm: 'polls',
          feature: 'polls'
        }
      },
      {
        path: 'polls/:id',
        component: () => import('pages/polls/Poll'),
        meta: {
          requiresAuth: true,
          userPerm: 'polls',
          feature: 'polls'
        }
      },
      {
        path: 'surveys',
        component: () => import('pages/surveys/Surveys'),
        meta: {
          requiresAuth: true,
          userPerm: 'surveys',
          feature: 'surveys'
        }
      },
      {
        path: 'surveys/:id',
        component: () => import('pages/surveys/Survey'),
        meta: {
          requiresAuth: true,
          userPerm: 'surveys',
          feature: 'surveys'
        }
      },
      {
        path: 'settings',
        component: () => import('pages/Settings'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'library',
        component: () => import('pages/library/Library'),
        meta: {
          requiresAuth: true,
          userPerm: 'library',
          feature: 'library'
        }
      },
      {
        path: 'library/:id',
        component: () => import('pages/library/Document'),
        meta: {
          requiresAuth: true,
          userPerm: 'library',
          feature: 'library'
        }
      },
      {
        path: 'team',
        component: () => import('pages/directory/Team'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'tickets',
        component: () => import('pages/tickets/Tickets'),
        meta: {
          requiresAuth: true,
          userPerm: 'tickets',
          feature: 'tickets'
        }
      },
      {
        path: 'tickets/:id',
        component: () => import('pages/tickets/Ticket'),
        meta: {
          requiresAuth: true,
          userPerm: 'tickets',
          feature: 'tickets'
        }
      },
      {
        path: 'learning',
        component: () => import('pages/learning/Learning'),
        meta: {
          requiresAuth: true,
          userPerm: 'learning',
          feature: 'learning'
        }
      },
      {
        path: 'learning/:id',
        component: () => import('pages/learning/LearningCourse'),
        meta: {
          requiresAuth: true,
          userPerm: 'learning',
          feature: 'learning'
        }
      },
      {
        path: 'forms',
        component: () => import('pages/forms/Forms'),
        meta: {
          requiresAuth: true,
          userPerm: 'forms',
          feature: 'forms'
        }
      },
      {
        path: 'forms/:id',
        component: () => import('pages/forms/Form'),
        meta: {
          requiresAuth: true,
          userPerm: 'forms',
          feature: 'forms'
        }
      },
      {
        path: 'form-templates/:id',
        component: () => import('pages/forms/FormTemplate'),
        meta: {
          requiresAuth: true,
          userPerm: 'forms',
          feature: 'forms'
        }
      }
    ]
  }
];

// Always leave this as last one
if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '*',
    component: () => import('pages/errors/Error404.vue')
  });
}

export default routes;
