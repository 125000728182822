// import firebase from 'firebase/app';
// import 'firebase/auth';
// import Vue from 'vue'

export function updateUserState(state, payload) {
  state[payload.state] = payload.data;
}

// export function updateUser(state, payload) {
//   state.uid = payload;
// }

export function updateDeviceID(state, payload) {
  state.deviceID = payload;
}
// export function updateUserList(state, payload) {
//   state.users = payload;
//   var currentUser = payload.find((e) => {
//     // return e.uid === firebase.auth().currentUser.uid
//     return e.email === firebase.auth().currentUser.email;
//   });
//   state.currentUser = currentUser;
//   // state.firstUse = currentUser.firstUse
// }
// export function updateCurrentUser(state, payload) {
//   state.currentUser = payload;
// }
export function updateClaims(state, payload) {
  state.claims = payload;
  // Vue.set(state.claims, payload.key, payload.value)
}
export function toggleNewsModal(state, payload) {
  state.newsOpen = payload;
}

// export function updateViewedUser(state, payload) {
//   state.viewedUser = payload;
// }

export function updateUserNotifications(state, payload) {
  state.userNotifications = payload;
}
export function updateHomePos(state, payload) {
  state.homePos = payload;
}
export function updateDirectoryPosition(state, payload) {
  state.dirPos = payload;
}
export function updateHomeWidgetsPosition(state, payload) {
  state.homeScreen[payload.widget] = payload.value;
}
export function updateUserLearning(state, payload) {
  state.userLearning = payload;
}

export function changeSearch(state, payload) {
  state.defSearch = payload;
}

export function updateTeamLearning(state, payload) {
  state.teamLearning[payload.user] = payload.data;
}
