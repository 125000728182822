export function getCompetitions({ commit }) {
  return this.$fs
    .collection('competitions')
    .orderBy('createdAt', 'desc')
    .get()
    .then((fsData) => {
      const list = [];
      fsData.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
      this.$logger('info', [`[action] getCompetitions`, list]);
      commit('updateCompetitionsState', {
        state: 'competitions',
        data: list
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getCompetitions', err]);
    });
}

export function createCompetition({ dispatch }, payload) {
  let id;

  return this.$fs
    .collection('competitions')
    .add(payload)
    .then(async (data) => {
      id = data.id;
      this.$logger('info', [`[action] createCompetition`, payload]);
      await dispatch('getCompetitions');
      dispatch('core/popToast', 'positive', {
        root: true
      });
      return id;
    })
    .catch((err) => {
      this.$logger('error', ['[action] createCompetition', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function updateCompetition({ dispatch }, payload) {
  return this.$fs
    .collection('competitions')
    .doc(payload.id)
    .update(payload)
    .then(async () => {
      this.$logger('info', [`[action] updateCompetitions`, payload]);
      await dispatch('getCompetitions');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] updateCompetitions', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function deleteCompetition({ dispatch }, payload) {
  return this.$fs
    .collection('competitions')
    .doc(payload)
    .delete()
    .then(async () => {
      this.$logger('info', [`[action] deleteCompetition`, payload]);
      await dispatch('getCompetitions');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] deleteCompetitions', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function getCompetitionComments({ commit }, payload) {
  return this.$fs
    .collection('competitionComments')
    .where('competitionId', '==', payload)
    .orderBy('createdAt', 'desc')
    .get()
    .then((fsData) => {
      const list = [];
      fsData.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
      this.$logger('info', [`[action] getCompetitionComments`, list]);
      commit('updateCompetitionsState', {
        state: 'competitionComments',
        data: list
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getCompetitionsComments', err]);
    });
}

export function getAllCompetitionComments({ commit }) {
  return this.$fs
    .collection('competitionComments')
    .orderBy('createdAt', 'desc')
    .get()
    .then((fsData) => {
      const list = [];
      fsData.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
      this.$logger('info', [`[action] getAllCompetitionComments`, list]);
      commit('updateCompetitionsState', {
        state: 'competitionComments',
        data: list
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getComments', err]);
    });
}

export function createCompetitionComment({ dispatch }, payload) {
  return this.$fs
    .collection('competitionComments')
    .add(payload)
    .then(async () => {
      this.$logger('info', [`[action] createCompetitionComment`, payload]);
      await dispatch('getCompetitionComments', payload.competitionId);
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] createCompetitionComment', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function updateCompetitionComment({ dispatch }, payload) {
  return this.$fs
    .collection('competitionComments')
    .doc(payload.data.id)
    .update(payload.data)
    .then(async () => {
      this.$logger('info', [`[action] updateCompetitionComment`, payload]);
      await dispatch('getCompetitionComments', payload.competition);
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] updateCompetitionComment', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function deleteCompetitionComment({ dispatch }, payload) {
  return this.$fs
    .collection('competitionComments')
    .doc(payload.id)
    .update({
      deleted: true,
      deletedAt: this.$fs2.FieldValue.serverTimestamp()
    })
    .then(async () => {
      this.$logger('info', [`[action] deleteCompetitionComment`, payload]);
      await dispatch('getCompetitionComments', payload.competition);
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] deleteEventComment', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function reportCompetitionComment(
  { dispatch },
  { comment, reportedBy, reportedAt, event }
) {
  return this.$fs
    .collection('competitionComments')
    .doc(comment.id)
    .update({
      requiresModeration: true,
      moderation: {
        reports: this.$fs2.FieldValue.arrayUnion({
          reportedAt,
          reportedBy
        })
      }
    })
    .then(async () => {
      this.$logger('info', [`[action] reportCompetitionComment`, comment]);
      await dispatch('getCompetitionComments', competition);
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] reportCompetitionComment', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function getReportedCompetitionComments({ commit }, payload) {
  return this.$fs
    .collection('competitionComments')
    .where('requiresModeration', '==', true)
    .orderBy('createdAt', 'desc')
    .get()
    .then((fsData) => {
      const list = [];
      fsData.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
      this.$logger('info', [`[action] getReportedCompetitionComments`, list]);
      commit('updateCompetitionState', {
        state: 'reportedComments',
        data: list
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getReportedEventComments', err]);
    });
}

export function respondToCompetition({ dispatch }, payload) {
  const responses = ['participating', 'declined'];
  return this.$fs
    .collection('competitions')
    .doc(payload.competition)
    .update({
      [`participants.${payload.response}`]: this.$fs2.FieldValue.arrayUnion(
        payload.user
      )
    })
    .then(async () => {
      const otherResponses = responses.filter((x) => x !== payload.response);
      for (const response of otherResponses) {
        await this.$fs
          .collection('competitions')
          .doc(payload.competition)
          .update({
            [`participants.${response}`]: this.$fs2.FieldValue.arrayRemove(
              payload.user
            )
          });
      }
    })
    .then(async () => {
      this.$logger('info', [`[action] respondToCompetition`, payload]);
      await dispatch('getCompetitions');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] respondToCompetition', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function startCompetitionListener({ commit }) {
  return this.$fs
    .collection('competitions')
    .orderBy('dateStart')
    .onSnapshot(
      (fsData) => {
        const list = [];
        fsData.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
        this.$logger('info', [`[action] startCompetitionsListener`, list]);
        commit('updateCompetitionsState', {
          state: 'competitions',
          data: list
        });
      },
      (err) => {
        this.$logger('error', ['[action] startCompetitionsListener', err]);
      }
    );
}

export function startCommentListener({ commit }, payload) {
  return this.$fs
    .collection('competitionComments')
    .where('competitionId', '==', payload)
    .orderBy('createdAt', 'desc')
    .onSnapshot(
      (fsData) => {
        const list = [];
        fsData.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
        this.$logger('info', [`[action] startCommentListener`, list]);
        commit('updateCompetitionState', {
          state: 'comments',
          data: list
        });
      },
      (err) => {
        this.$logger('error', ['[action] startCommentListener', err]);
      }
    );
}

export function getCompetitionsDefaults({ commit }) {
  return this.$fs
    .collection('setup')
    .doc('competitions')
    .get()
    .then((doc) => {
      this.$logger('info', [`[action] getCompetitionsDefaults`, doc]);
      if (doc.exists) {
        commit('updateCompetitionsState', {
          state: 'defaults',
          data: doc.data()
        });
      }
    })
    .catch((err) => {
      this.$logger('error', ['[action] getCompetitions', err]);
    });
}
