export function getSites({ commit }) {
  return this.$fs
    .collection('sites')
    .get()
    .then((fsData) => {
      const list = [];
      fsData.forEach((doc) => {
        list.push({ uid: doc.id, ...doc.data() });
      });
      this.$logger('info', [`[action] getSites`, list]);
      commit('updateSitesState', {
        state: 'sites',
        data: list
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getSites', err]);
    });
}

export function createSite({ dispatch }, payload) {
  return this.$fs
    .collection('sites')
    .add(payload)
    .then(async () => {
      this.$logger('info', [`[action] createSite`, payload]);
      await dispatch('getSites');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] createSite', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function updateSite({ dispatch }, payload) {
  return this.$fs
    .collection('sites')
    .doc(payload.uid)
    .update(payload)
    .then(async () => {
      this.$logger('info', [`[action] updateSite`, payload]);
      await dispatch('getSites');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] updateSite', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function deleteSites({ dispatch }, payload) {
  const deleteActions = [];

  const deleteAction = (id) =>
    this.$fs
      .collection('sites')
      .doc(id)
      .delete();

  for (const site of payload) {
    deleteActions.push(deleteAction(site));
  }

  return Promise.all(deleteActions)
    .then(async () => {
      await dispatch('getSites');
      dispatch('core/popToast', 'positive', {
        root: true
      });
      this.$logger('info', [`[action] deleteSites`, payload]);
    })
    .catch((err) => {
      this.$logger('error', ['[action] deleteSites', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}
