export function updateAdminState(state, payload) {
  state[payload.state] = payload.data;
}
export const updateAnalytics = (state, payload) => {
  state.analytics[payload.type] = payload.data;
};
export const updateSingleGrade = (state, payload) => {
  state.grades[payload.index].name = payload.val;
};
export const signupEvent = (state, payload) => {
  state.signUpData = payload;
};
export const updateNetworkInfo = (state, payload) => {
  state.online = payload;
};
// export const createOtherCat = (state, payload) => {
//   state.ticketCategories = payload;
// };
