import VueI18n from 'vue-i18n';
import messages from 'src/i18n';

export default async ({ app, Vue, store }) => {
  Vue.use(VueI18n);

  // Set i18n instance on app

  const i18n = new VueI18n({
    locale: 'en-gb',
    fallbackLocale: 'en-gb',
    messages
  });

  app.i18n = i18n;
  store.$i18n = i18n;
};
