import VueI18nCountries from 'i18n-iso-countries';
// import messages from 'src/i18n'

export default async ({ app, Vue }) => {
  // Set i18nCountries instance on app
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/fr.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/pl.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/de.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/es.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/ar.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/az.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/be.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/bg.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/bn.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/bs.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/ca.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/cs.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/da.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/et.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/fa.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/fi.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/gl.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/el.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/he.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/hi.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/hr.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/hu.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/hy.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/it.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/id.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/ja.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/ka.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/kk.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/ko.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/ky.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/lt.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/lv.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/mk.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/mn.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/ms.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/nb.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/nl.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/nn.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/pt.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/ro.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/ru.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/sk.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/sl.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/sr.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/sv.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/th.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/tr.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/uk.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/ur.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/uz.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/zh.json'));
  VueI18nCountries.registerLocale(require('i18n-iso-countries/langs/vi.json'));

  Vue.prototype.$countries = VueI18nCountries;
};
