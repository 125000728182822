export function publishedArticles(state) {
  return state.articles.filter((article) => article.published);
}

export const articleComments = (state) => (id) => {
  return state.comments.filter(
    (comment) =>
      comment.articleId === id && !comment.deleted && !comment.moderatedAt
  );
};

export const getChannelArticles = (state) => (channelId) => {
  return state.articles
    .filter((x) => x.channel === channelId)
    .filter((x) => x.published);
};
