<template>
  <q-dialog
    v-model="showing"
    seamless
    position="bottom"
  >
    <q-banner
      v-if="$q.platform.is.android"
      inline-actions
    >
      {{ $t('appStores.google') }}
      <template #action>
        <a href="https://play.google.com/store/apps/details?id=com.impulseworkplace.mmc&hl=en_GB">
          <img
            src="gp-badge.svg"
            style="height: 60px"
          >
        </a>

        <q-btn
          v-close-popup
          color="primary"
          flat
          icon="close"
        />
      </template>
    </q-banner>
    <q-banner
      v-if="$q.platform.is.ios && currentUser.appleLink"
      inline-actions
    >
      {{ $t('appStores.apple') }}
      <template #action>
        <a
          :href="currentUser.appleLink"
          class="q-pa-sm"
        >
          <img
            src="as-badge.svg"
            style="height: 40px; vertical-align: middle"
          >
        </a>

        <q-btn
          v-close-popup
          color="primary"
          flat
          icon="close"
        />
      </template>
    </q-banner>
  </q-dialog>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'AppLinkDialog',

  data() {
    return {
      showing: true
    };
  },
  computed: {
    ...mapState('user', ['currentUser'])
  }
};
</script>
