export const getUserPolls = (state) => (id) => {
  const now = new Date();

  return state.polls
    .filter((x) => x.invitees.includes(id))
    .filter((x) => {
      return x.open.from.toDate() <= now && x.open.to.toDate() >= now;
    });
};

export const singlePoll = (state) => (id) => {
  return state.polls.find((x) => x.id === id);
};

export const singlePollResults = (state) => {
  const rawResults = state.currentPollResults;
  const results = Object.keys(rawResults).map((x) => {
    return rawResults[x].vote;
  });
  return results;
};
