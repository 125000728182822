export const cordovaInit = {
  methods: {
    cordovaInit() {
      //request permission for push notifications
      cordova.plugins.firebase.messaging
        .requestPermission({ forceShow: true })
        .then(() => {
          console.log('Push messaging is allowed');
        });

      //get push notification token
      this.getFCMToken();
      if (this.$auth.currentUser) {
        // Updating token
        console.log('token added');

        this.$store.dispatch(
          'user/addFCMDeviceToken',
          this.$store.state.user.deviceID
        );
      }

      //update token when refreshed
      cordova.plugins.firebase.messaging.onTokenRefresh(() => {
        // this.getFCMToken();
        console.log('token refreshed');
        this.$store.dispatch('user/addFCMDeviceToken', this.getFCMToken());
      });

      //handle background push notifications
      cordova.plugins.firebase.messaging.onBackgroundMessage((payload) => {
        console.log(payloadv);
        cordova.plugins.firebase.messaging.getBadge().then(function(value) {
          console.log('Badge value: ', value);
          cordova.plugins.firebase.messaging.setBadge(value + 1);
        });
        console.dir('got push', payload);
        if (payload.type === 'newChat') {
          this.$router.push({
            path: `/chat/${payload.chatId}/messages`
          });
        }
        if (payload.type === 'newNews') {
          this.$router.push({
            path: `/articles/${payload.id}`
          });
        }
        if (payload.type === 'newBlog') {
          this.$router.push({
            path: `/blog/${payload.id}`
          });
        }
        if (payload.type === 'eventInvite') {
          this.$router.push({
            path: `/events/${payload.id}`
          });
        }
        if (payload.type === 'newProblem') {
          this.$router.push({
            path: `/admin/tickets`
          });
        }
      });

      //Handle new push notificaions in foreground
      cordova.plugins.firebase.messaging.onMessage((payload) => {
        console.log('New foreground FCM message: ', JSON.stringify(payload));
        // if (this.$route.path !== '/chat' && !this.$q.platform.is.ios) {
        //   this.$q.notify({
        //     position: 'top',
        //     color: 'accent',
        //     textColor: 'black',
        //     message: this.$q.platform.is.android
        //       ? `${payload.gcm.title}: ${payload.gcm.body}`
        //       : `${payload.aps.alert.title}: ${payload.aps.alert.body}`,
        //     // actions: [{ icon: 'close', color: 'white' }],
        //     timeout: 5000
        //   });
        // }
      });

      //listen for network changes and toggle firebase network state
      document.addEventListener(
        'offline',
        this.toggleFireStoreNetwork(false),
        false
      );
      document.addEventListener(
        'online',
        this.toggleFireStoreNetwork(true),
        false
      );

      // listen to incoming univesal links
      universalLinks.subscribe('finishSignup', (eventData) => {
        this.$store.commit('admin/signupEvent', eventData);
        this.$router.push({
          path: '/login/finish-signup',
          query: eventData.params
        });
      });
      universalLinks.subscribe('phoneSignup', (eventData) => {
        this.$store.commit('admin/signupEvent', eventData);
        this.$router.push(
          {
            path: '/login/with-phone',
            query: eventData.params
          },
          () => {
            console.log('complete');
          },
          (err) => {
            console.error('error', err);
          }
        );
      });
    },
    async getFCMToken() {
      console.log('getting token');
      return await cordova.plugins.firebase.messaging
        .getToken()
        .then((token) => {
          this.$store.commit('user/updateDeviceID', token);
          return token;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    toggleFireStoreNetwork(online) {
      if (!online) {
        this.$fs.disableNetwork().then(() => {
          this.updateNetworkInfo(false);
          console.log('offline');
        });
      } else {
        this.$fs.enableNetwork().then(() => {
          this.updateNetworkInfo(true);
          console.log('online');
        });
      }
    }
  }
};
