export const updateChatState = (state, payload) => {
  state[payload.state] = payload.data;
};

export const updateChats = (state, payload) => {
  state.chats = payload;
};
export const updateParticipants = (state, payload) => {
  state.participants = payload;
};
export const updateForwardContent = (state, payload) => {
  state.forwardContent = payload;
};
export const copyMessage = (state, payload) => {
  state.copiedMessage = payload;
};
export const msgReplied = (state, payload) => {
  state.messageReplied = payload;
};
