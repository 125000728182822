export function getFormTemplates({ commit }) {
  return this.$fs
    .collection('formTemplates')
    .get()
    .then((fsData) => {
      const list = [];
      fsData.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
      this.$logger('info', [`[action] getFormTemplates`, list]);
      commit('updateFormsState', {
        state: 'formTemplates',
        data: list
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getFormTemplates', err]);
    });
}

export function createFormTemplate({ dispatch }, payload) {
  return this.$fs
    .collection('formTemplates')
    .add(payload)
    .then(async () => {
      this.$logger('info', [`[action] createFormTemplate`, payload]);
      await dispatch('getFormTemplates');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] createFormTemplate', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function updateFormTemplate({ dispatch }, payload) {
  return this.$fs
    .collection('formTemplates')
    .doc(payload.id)
    .update(payload)
    .then(async () => {
      this.$logger('info', [`[action] updateFormTemplate`, payload]);
      await dispatch('getFormTemplates');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] updateFormTemplate', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function deleteFormTemplate({ dispatch }, payload) {
  return this.$fs
    .collection('formTemplates')
    .doc(payload)
    .delete()
    .then(async () => {
      this.$logger('info', [`[action] deleteFormTemplate`, payload]);
      await dispatch('getFormTemplates');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] deleteFormTemplate', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function getForms({ commit }) {
  return this.$fs
    .collection('forms')
    .get()
    .then((fsData) => {
      const list = [];
      fsData.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
      this.$logger('info', [`[action] getForms`, list]);
      commit('updateFormsState', {
        state: 'forms',
        data: list
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getForms', err]);
    });
}

export function createForm({ dispatch }, payload) {
  return this.$fs
    .collection('forms')
    .add(payload)
    .then(async () => {
      this.$logger('info', [`[action] createForm`, payload]);
      await dispatch('getForms');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] createForm', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function updateForm({ dispatch }, payload) {
  return this.$fs
    .collection('forms')
    .doc(payload.id)
    .update(payload)
    .then(async () => {
      this.$logger('info', [`[action] updateForm`, payload]);
      await dispatch('getForms');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] updateForm', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function deleteForm({ dispatch }, payload) {
  return this.$fs
    .collection('forms')
    .doc(payload)
    .delete()
    .then(async () => {
      this.$logger('info', [`[action] deleteForm`, payload]);
      await dispatch('getForms');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] deleteForm', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function getDeadline({ commit }) {
  return this.$fs
    .collection('formsDeadline')
    .doc('deadline')
    .get()
    .then((doc) => {
      commit('updateFormsState', {
        state: 'deadline',
        data: doc.data()
      });
      return doc.data();
    })
    .catch((err) => {
      this.$logger('error', ['[action] getDeadline', err]);
    });
}

export function updateDeadline({ dispatch }, payload) {
  return this.$fs

    .collection('formsDeadline')

    .doc('deadline')

    .set({ days: +payload }, { merge: true })

    .then(() => {
      dispatch('getDeadline');

      this.$logger('info', [`[action] getDeadline`, payload]);

      dispatch('core/popToast', 'positive', {
        root: true
      });
    })

    .catch((err) => {
      this.$logger('error', ['[action] updateDeadline', err]);

      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}
