export default {
  login: false,
  reply: false,
  event: false,
  group: false,
  user: false,
  news: false,
  blog: false,
  link: false,
  push: false,
  postImage: false,
  firstUse: false,
  toggleDefaultArticleImage: false,
  userStatuses: false,
  channelsAdmin: false
};
