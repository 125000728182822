export function getVacancies({ commit }) {
  return this.$fs
    .collection('vacancies')
    .get()
    .then((fsData) => {
      const list = [];
      fsData.forEach((doc) => {
        list.push({ uid: doc.id, ...doc.data() });
      });
      this.$logger('info', [`[action] getVacancies`, list]);
      commit('updateVacanciesState', {
        state: 'vacancies',
        data: list
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getVacancies', err]);
    });
}

export function createVacancy({ commit, dispatch }, payload) {
  return this.$fs
    .collection('vacancies')
    .add(payload)
    .then(() => {
      this.$logger('info', [`[action] createVacancy`, payload]);
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] createVacancy', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function updateVacancy({ commit, dispatch }, payload) {
  return this.$fs
    .collection('vacancies')
    .doc(payload.uid)
    .update(payload)
    .then(() => {
      this.$logger('info', [`[action] updateVacancy`, payload]);
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] updateVacancy', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}

export function deleteVacancies({ dispatch }, payload) {
  const deleteActions = [];

  const deleteAction = (id) =>
    this.$fs.collection('vacancies').doc(id).delete();

  for (const site of payload) {
    deleteActions.push(deleteAction(site));
  }

  return Promise.all(deleteActions)
    .then(async () => {
      await dispatch('getVacancies');
      dispatch('core/popToast', 'positive', {
        root: true
      });
      this.$logger('info', [`[action] deleteVacancies`, payload]);
    })
    .catch((err) => {
      this.$logger('error', ['[action] deleteVacancies', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}
