import { Loading, Notify } from 'quasar';

export function initApp({ commit, dispatch }) {
  console.log('[IMPulse] begin init');
  Loading.show();
  const getFeatures = dispatch('admin/listFeatures', null, { root: true });
  const getRoles = dispatch('admin/getRoles', null, { root: true });
  const getPermissions = dispatch('admin/listPermissions', null, {
    root: true
  });
  const getLicenseDetails = dispatch('admin/getLicenseDetails', null, {
    root: true
  });
  const getWelcomeNotice = dispatch('admin/getWelcomeNotice', null, {
    root: true
  });
  const getChats = dispatch('chat/startChatListener', null, { root: true });
  const getTickets = dispatch('tickets/getTickets', null, { root: true });
  const getNotifications = dispatch(
    'notifications/startUserNotificationsListener',
    null,
    { root: true }
  );
  const getUsers = dispatch('user/getUsers', null, { root: true });
  const getVacancies = dispatch('vacancies/getVacancies', null, { root: true });
  const getLibrary = dispatch('library/getLibrary', null, { root: true });

  const getLearning = dispatch('learning/getLearning', null, {
    root: true
  });
  const getLearningCategories = dispatch(
    'learning/getLearningCategories',
    null,
    { root: true }
  );
  const getChannels = dispatch('admin/getChannels', null, { root: true });
  const getSites = dispatch('sites/getSites', null, { root: true });
  const getGrades = dispatch('admin/getUserGrades', null, { root: true });
  const getStatuses = dispatch('admin/getWorkStatuses', null, { root: true });
  const getProblemCategories = dispatch('admin/getTicketCategories', null, {
    root: true
  });
  const getStructure = dispatch('admin/getStructure', null, { root: true });
  const getFolderStructure = dispatch('library/getStructure', null, {
    root: true
  });
  const getArticles = dispatch('articles/getArticles', null, { root: true });
  const getUserContent = dispatch('articles/getUserContent', null, {
    root: true
  });
  const getCompetitions = dispatch('competitions/getCompetitions', null, {
    root: true
  });

  const getArticlesDefaults = dispatch('articles/getArticlesDefaults', null, {
    root: true
  });
  const getCompetitionsDefaults = dispatch(
    'competitions/getCompetitionsDefaults',
    null,
    {
      root: true
    }
  );
  const getComments = dispatch('articles/getComments', null, { root: true });
  const getEventComments = dispatch('events/getAllEventComments', null, {
    root: true
  });
  const getCompetitionComments = dispatch(
    'competitions/getAllCompetitionComments',
    null,
    { root: true }
  );
  const getSurveys = dispatch('surveys/getSurveys', null, { root: true });
  const getPolls = dispatch('polls/getPolls', null, { root: true });
  const getEvents = dispatch('events/getEvents', null, { root: true });
  const getEventCategories = dispatch('events/getEventCategories', null, {
    root: true
  });
  const getMenus = dispatch('menus/getMenus', null, { root: true });
  const getLocales = dispatch('admin/getLocales', null, { root: true });
  const getFormAdmins = dispatch('admin/getFormAdmins', null, { root: true });
  //const getTicketAdmins = dispatch('admin/getTicketAdmins', null, { root: true });
  //const getTicketUsers = dispatch('admin/getTicketUsers', null, { root: true });
  // const getTicketAdminsAndUsers = dispatch('admin/getTicketAdminsAndUsers', null, { root: true });

  getFormAdmins;

  return Promise.all([
    getFeatures,
    getPermissions,
    getLicenseDetails,
    getWelcomeNotice,
    getLocales,
    getChats,
    getTickets,
    getNotifications,
    getUsers,
    getVacancies,
    getLibrary,
    // getFormAdmins,
    getLearning,
    getLearningCategories,
    getChannels,
    getSites,
    getGrades,
    getStatuses,
    getProblemCategories,
    getStructure,
    getFolderStructure,
    getArticles,
    getArticlesDefaults,
    getUserContent,
    getCompetitionsDefaults,
    getComments,
    getEventComments,
    getCompetitionComments,
    getSurveys,
    getPolls,
    getEvents,
    getCompetitions,
    getEventCategories,
    getMenus
  ]).then(() => {
    commit('updateCoreState', { state: 'initComplete', data: true });
    console.log('[IMPulse] init completed');

    if (navigator.splashscreen) {
      console.log('REMOVING SPLASH', navigator.splashscreen);
      navigator.splashscreen.hide();
    }

    Loading.hide();
  });
}

export function toggleLoading(context, loadingState) {
  loadingState ? Loading.show() : Loading.hide();
}

export function popToast(context, type) {
  Notify.create({
    type,
    message:
      type === 'positive'
        ? this.$i18n.t('common.toasts.success')
        : this.$i18n.t('common.toasts.failed'),
    position: 'top',
    timeout: 1500
  });
}

export function deleteFileByUrl(context, payload) {
  return this.$stor
    .refFromURL(payload.url)
    .delete()
    .then(() => {
      this.$logger('info', [`[action] deleteFileByUrl`, payload]);
      if (!payload.silent) {
        dispatch('core/popToast', 'positive', {
          root: true
        });
      }
    });
}
