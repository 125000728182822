export default {
  perms: null,
  features: {},
  license: {},
  suggestions: null,
  tickets: [],
  userStatuses: [],
  grades: [],
  theme: {},
  ticketCategories: [],
  analytics: {
    dau: {}
  },
  structure: [],
  flatStructure: [],
  channels: [],
  signUpData: {},
  suggestionRecipient: null,
  online: null,
  ticketAdmins: [],
  ticketUsers: [],
  formAdmins: [],
  locales: {},
  welcomeNotice: {},
  userClaims: {},
  roles: [],
  superUsers: [],
  roleCounts: {}
};
