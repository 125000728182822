/*
export function someGetter (state) {
}
*/

export const getLearning = (state) => {
  return [...state.learning].sort((a, b) => {
    if (!a.endDate || !b.endDate) {
      return 0;
    } else if (a.endDate < b.endDate) {
      return -1;
    } else if (a.endDate == b.endDate) {
      return 0;
    } else {
      return 1;
    }
  });
};

export const getAllLearningResults = (state) => {
  return state.results;
};
