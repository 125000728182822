export function getBroadcasts({ commit }) {
  return this.$fs
    .collection('broadcasts')
    .get()
    .then((fsData) => {
      const broadcasts = [];
      fsData.forEach((doc) => broadcasts.push({ id: doc.id, ...doc.data() }));
      this.$logger('info', [`[action] getBroadcasts`, broadcasts]);
      commit('updateBroadcastState', {
        state: 'broadcasts',
        data: broadcasts
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] getBroadcasts', err]);
    });
}

export function createBroadcast({ dispatch }, payload) {
  let id;
    this.$fs
    .collection('broadcasts')
    .add({
      ...payload,
      dateSent:payload.sendNow? this.$fs2.FieldValue.serverTimestamp():null
    })
    .then(async (data) => {
      id=data.id;
      await dispatch('getBroadcasts');
      this.$logger('info', [`[action] getBroadcasts`, payload]);
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      console.error(err);
      this.$logger('error', ['[action] getBroadcasts', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    })
    .then(()=>{
      if(payload.sendNow){
        return this.$func.httpsCallable('broadcast-sendBroadcast')(payload);
      }
      else{
        return this.$func.httpsCallable('broadcast-broadcastTaskCreator')({...payload.notificationPayload,id});
      }
    });
   
  }  

export function updateBroadcast({ dispatch }, payload) {
  return this.$fs
    .collection('broadcasts')
    .doc(payload.id)
    .update(payload)
    .then(async () => {
      this.$logger('info', [`[action] updateBroadcast`, payload]);
      await dispatch('getBroadcasts');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] updateBroadcast', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    })
    .then(()=>{
      if(payload.notificationPayload.startChange){
        return this.$func.httpsCallable('broadcast-broadcastTaskCreator')({...payload.notificationPayload,id:payload.id});
      }
    });
}

export function deleteBroadcast({ dispatch }, payload) {
  return this.$fs
    .collection('broadcasts')
    .doc(payload)
    .delete()
    .then(async () => {
      this.$logger('info', [`[action] deleteBroadcast`, payload]);
      await dispatch('getBroadcasts');
      dispatch('core/popToast', 'positive', {
        root: true
      });
    })
    .catch((err) => {
      this.$logger('error', ['[action] deleteBroadcast', err]);
      dispatch('core/popToast', 'negative', {
        root: true
      });
    });
}
