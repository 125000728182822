export default {
  learning: [],
  userLearning: {},
  learning: {},
  results: [],
  currentCourse: null,
  currentCourseUserInfo: null,
  categories: [],
  teamLearning: {}
};
