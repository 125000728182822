import Vue from 'vue';
import Vuex from 'vuex';
import { createLogger } from 'vuex';

import core from './core';
import user from './user';
import articles from './articles';
import events from './events';
import modals from './modals';
import notifications from './notifications';
import admin from './admin';
import chat from './chat';
import tickets from './tickets';
import menus from './menus';
import surveys from './surveys';
import polls from './polls';
import sites from './sites';
import vacancies from './vacancies';
import library from './library';
import learning from './learning';
import broadcasts from './broadcasts';
import selected from './selected';
import competitions from './competitions';
import forms from './forms';

Vue.use(Vuex);

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation
 */

export default function(/* { ssrContext } */) {
  const Store = new Vuex.Store({
    plugins: process.env.DEV
      ? [createLogger({ logActions: true, logMutations: false })]
      : [],
    modules: {
      core,
      user,
      articles,
      events,
      modals,
      notifications,
      admin,
      chat,
      tickets,
      menus,
      surveys,
      polls,
      sites,
      vacancies,
      library,
      learning,
      broadcasts,
      selected,
      competitions,
      forms
    },

    //mergeComment
    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: process.env.DEV
  });

  return Store;
}
